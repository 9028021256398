import { render, staticRenderFns } from "./TechnologyWeakSignals.vue?vue&type=template&id=18ef96e8&scoped=true&"
import script from "./TechnologyWeakSignals.vue?vue&type=script&lang=js&"
export * from "./TechnologyWeakSignals.vue?vue&type=script&lang=js&"
import style0 from "./TechnologyWeakSignals.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TechnologyWeakSignals.vue?vue&type=style&index=1&id=18ef96e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ef96e8",
  null
  
)

export default component.exports