<template>
    <div class="fst-StructuredQuery">
        <div class="queryTab">
            <table>
                <tr>
                    <td class="text-right">
                        <label>{{$t('label.searchFor')}}:</label>
                    </td>
                    <td>
                        <el-select multiple v-model="filterFirst.fields">
                            <el-option v-for="(item, key) in fieldOptions"
                                       :label="$t('structuredQueryLabel.' + item.name)"
                                       :key="key"
                                       :value="item.name"
                            ></el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-input v-model="filterFirst.query"
                                  @keyup.native.enter="apply()"
                                  :placeholder="$t('label.keywords')"></el-input>
                    </td>
                    <td>
                        <el-button type="primary" @click="apply()" class="px-3">
                            <i class="far fa-search"></i>
                        </el-button>
                    </td>
                </tr>
                <tr v-for="(row, idx) in filterOther" :key="idx">
                    <td>
                        <el-select v-model="row.operator">
                            <el-option label="AND" value="AND"></el-option>
                            <el-option label="OR" value="OR"></el-option>
                            <el-option label="NOT" value="AND_NOT"></el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-select multiple v-model="row.fields">
                            <el-option v-for="(item, key) in fieldOptions"
                                       :label="$t('structuredQueryLabel.' + item.name)"
                                       :key="key"
                                       :value="item.name"
                            ></el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-input v-model="row.query"
                                  @keyup.native.enter="apply()"
                                  :placeholder="$t('label.keywords')"></el-input>
                    </td>
                    <td>
                        <el-button type="text" class="py-0" @click="removeFilterRow(idx)">
                            <i class="fas p-2 fa-times"></i>
                        </el-button>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <el-button type="text" size="mini"
                                   @click="addFilterRow"
                                   :disabled="!filterFirst.query">
                            <i class="far fa-plus "></i>
                            {{$t('label.addAnotherField')}}
                        </el-button>
                    </td>
                    <td class="text-right">
                        <el-button type="text" size="mini" @click="closeIt()" class="closeIt">
                            {{$t('label.backToSearch')}}
                            <i class="fa-arrow-alt-right far ml-1"></i>
                        </el-button>
                        <el-button type="primary" plain size="mini" v-if="false"
                                   @click="$emit('showSaveBtn')">
                            <i class="fas fa-save"></i>
                        </el-button>

                    </td>
                    <td></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "StructuredQuery",
        props: {
            docType: {
                type: String,
                default: 'Science',
            },
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            ...mapState({
                structuredQuery: state => state.structuredQuery,
                showStructuredQuery: state => state.showStructuredQuery
            }),
            fieldOptions() {
                return this.fields.filter(it => {
                    return !it.availableTo || it.availableTo.includes(this.docType)
                })
            }
        },
        watch: {},
        data() {
            return {
                fields: [
                    {name: 'title'},
                    {name: 'description'},
                    {name: 'cpcs_code', code: 'cpcs.code', availableTo: ['Patents']},
                    {name: 'companies'},
                    {name: 'fullText', availableTo: ['ScienceOpen']},
                ],
                newFilterRow: {
                    operator: 'AND',
                    fields: ['title'],
                    query: ''
                },
                filterFirst: {
                    fields: ['title'],
                    query: '',
                },
                filterOther: [],
            }
        },
        methods: {
            apply() {
                let _rows = _.cloneDeep([this.filterFirst].concat(this.filterOther)),
                    // _rows = _.cloneDeep([this.filterFirst].concat(this.filterOther)).map(it => {
                    //     it.fields = [it.field];
                    //     delete it.field;
                    //     return it
                    // }),
                    structuredQuery = {
                        rows: _rows
                    }
                // console.log(':: structuredQuery ::', structuredQuery);
                this.updateStructuredQuery(structuredQuery);
                this.$nextTick(() => {
                    this.$emit('search')
                });
            },
            updateStructuredQuery(structuredQuery) {
                this.$store.commit('updateState', [{
                    path: 'structuredQuery',
                    data: structuredQuery,
                }])
            },
            addFilterRow() {
                this.filterOther.push(_.cloneDeep(this.newFilterRow));
            },
            removeFilterRow(row) {
                this.filterOther.splice(row, 1);
            },
            closeIt() {
                this.$store.commit('updateState', [{
                    path: 'showStructuredQuery',
                    data: false,
                }, {
                    path: 'structuredQuery',
                    data: null,
                }]);
                this.filterOther = [];
                this.filterFirst.query = '';
                this.$nextTick(() => {
                    this.$emit('search')
                });
            },
            loadStructuredQuery() {
                let _queryRows = (this.structuredQuery || {}).rows || [],
                    _first = _queryRows.shift();
                this.filterFirst = _first || {
                    fields: ['title'],
                    query: '',
                }
                if (_queryRows.length) {
                    this.filterOther = _queryRows;
                }
            }
        }
    }
</script>

<style lang="scss">
    .fst-StructuredQuery {
        flex: 1;
        display: flex;
        gap: 3px;
        align-items: flex-start;
        justify-content: space-between;
        margin: -2px -6px -18px 0;

        .queryTab {
            flex: 1;

            > table {
                width: 100%;

                td {
                    padding: 1px 5px 1px 0;
                    vertical-align: top;

                    label {
                        margin-top: 6px;
                        display: inline-block;
                    }

                    &:first-child {
                        width: 120px;
                    }

                    &:nth-child(3) {
                        width: 54%;
                    }

                    &:last-child {
                        width: 32px;
                    }
                }
                tr:first-child{
                    td:nth-child(3){
                        .el-input__inner{
                            border-radius: 4px 0 0 4px;
                        }
                    }
                    .el-button {
                        margin-left: -8px;
                        border-radius: 0 4px 4px 0;
                    }

                }
            }

        }

        .closeIt {
            padding: 5px 1px;
            font-size: 13px;
        }
    }

    .fst-Header {
        .fst-StructuredQuery {
            .el-select {
                .el-input--small .el-input__inner {
                    border: $border;
                    color: #333;
                    font-weight: 400;
                    font-size: 13px;
                }
            }
        }
    }

</style>