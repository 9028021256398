<template>
    <div class="fst-Users">
        <div class="admin-content mb-3">
            <el-row type="flex" class="action-panel" :gutter="10">
                <el-col :span="10" :md="10">
                    <el-input v-model="userSearchString" :placeholder="$t('admin.users.search')" clearable>
                        <template slot="prepend">
                            <i class="el-icon-search"/>
                        </template>
                        <template slot="append">
                            <span v-if="userSearchString.length">{{ searchList.length }}</span>
                            <span v-else>{{ UsersModel.offset + 1 }} - {{
                                    UsersModel.offset + searchList.length
                                }}</span>
                            <span> / {{ UsersModel.total }}</span>
                        </template>
                    </el-input>
                </el-col>
                <el-col :span="12" :md="12">
                    <el-select v-model="status" @change="loadUsers()">
                        <el-option
                                v-for="item in statuses"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="ml-2" @click="editUser()">
                        <i class="far fa-plus mr-1"></i>
                        {{ $t('admin.users.add') }}
                    </el-button>
                    <el-button type="success" plain class="ml-2" @click="handleShowReqList">
                        <i class="far mr-1 fa-list"></i>
                        {{ $t('admin.users.requestList') }}
                        <span v-if="reqList.length" class="ml-1 d-inline-block">({{reqList.length}})</span>
                    </el-button>
                </el-col>
                <el-col :span="4" class="d-flex justify-content-end">
                    <span class="dashboardExport" @click="handleExportUsers">
                        <i class="fas fa-pulse fa-spinner" v-if="exportLoading"></i>
                        <svg v-else width="16" height="19" viewBox="0 0 16 19" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z"
                                  fill="currentColor"/>
                        </svg>
                        Export to excel
                    </span>
                </el-col>
            </el-row>
        </div>
        <div class="admin-content">
            <el-table class="users-table" :data="searchList" stripe border
                      height="74.5vh"
                      :empty-text="$t('admin.users.table.empty')"
                      @row-dblclick="handleRowDblclick">
                <el-table-column type="index" label="№" width="50"/>
                <el-table-column prop="state" width="36">
                    <template slot="header">
                        <span class="far fa-user-lock" :title="$t('admin.users.statusTitle')"></span>
                    </template>
                    <template slot-scope="scope">
                        <span class="far"
                              :class="{'fa-check-square':scope.row.state === 'ENABLED', 'fa-square':scope.row.state === 'LOCKED'}"
                              :title="$t('admin.users.status.' + scope.row.state)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="ID" class-name="small" width="85"/>
                <el-table-column :label="$t('admin.users.table.name')">
                    <template slot-scope="scope">
                        <text-highlight :queries="[userSearchString]" :highlightClass="'markIt'">
                            {{ scope.row.fullName }}
                        </text-highlight>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('admin.users.table.login')">
                    <template slot-scope="scope">
                        <text-highlight :queries="[userSearchString]" :highlightClass="'markIt'">
                            {{ scope.row.username }}
                        </text-highlight>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="E-mail"/>
                <el-table-column label="Company">
                    <template slot-scope="scope" v-if="scope.row.company">
                        <text-highlight :queries="[userSearchString]" :highlightClass="'markIt'">
                            {{ scope.row.company.name }}
                        </text-highlight>
                    </template>
                </el-table-column>
                <el-table-column prop="dateCreate" :formatter="dateCreate"
                                 label="Creation date"/>
                <el-table-column prop="dateAccountExpire" :formatter="dateAccountExpire"
                                 :label="$t('admin.users.table.dateAccountExpire')"/>
                <el-table-column :label="$t('actions')" align="right" width="90">
                    <template slot-scope="scope">
                        <el-dropdown class="mr-2" @command="handleActionCommand" trigger="click">
                            <el-link type="text" size="mini" icon="far fa-edit"/>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="a in actions" :key="a.id" :icon="a.icon"
                                                  :command="{action: a, user: scope.row}"
                                                  :disabled="a.access && !a.access(scope.row)">{{ a.name }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-link type="danger" size="mini" @click="lockUser(scope.row.id, scope.row.state)">
                            <i :class="['far', (scope.row.state === 'LOCKED') ? 'fa-lock-open' : 'fa-lock']"></i>
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-row v-if="!userSearchString.length" class="user-pagination text-right">
                <el-pagination
                        small
                        background
                        :hide-on-single-page="true"
                        layout="prev, pager, next"
                        :total="UsersModel.total"
                        :page-size="UsersModel.count"
                        :current-page="currentPage"
                        @current-change="onChangePage"/>
            </el-row>
        </div>
        <el-drawer
                custom-class="reqList"
                :title="$t('admin.users.requestList')"
                :visible.sync="show.showReqList"
                :close-on-press-escape="true"
                @close="handleCloseReqList"
                size="95%">
            <div class="filter flex-center-between">
                <div class="flex-center">
                    {{$t('admin.reqList.reqStatus')}}:
                    <el-select v-model="reqListStatus" @change="getReqList" class="ml-2">
                        <el-option :label="$t('admin.reqList.pending')" value="pending"></el-option>
                        <el-option :label="$t('admin.reqList.approved')" value="approved"></el-option>
                        <el-option :label="$t('admin.reqList.declined')" value="declined"></el-option>
                    </el-select>
                    <el-tag class="ml-2" size="medium" :type="reqListStatus === 'approved' ? 'success'
                    : reqListStatus === 'declined' ? 'warning'
                        : ''" :effect="reqListStatus === 'pending' ? 'light' : 'dark'">{{reqList.length}}
                    </el-tag>
                </div>
                <div class="flex-center">
                    <el-button type="primary" :size="'mini'" plain @click="getReqList"><i class="far fa-sync"></i>
                    </el-button>
                </div>
            </div>
            <el-table class="users-table" :data="reqList" stripe border
                      height="50vh"
                      :default-sort="{prop: 'date', order: 'descending'}"
                      :empty-text="$t('admin.users.table.empty')">
                <el-table-column prop="dateCreate" sortable :label="$t('admin.reqList.regDate')" width="130">
                    <template slot-scope="scope">
                        <div class="date">{{ scope.row.date}}</div>
                    </template>

                </el-table-column>
                <el-table-column :label="$t('admin.reqList.name')" width="250">
                    <template slot-scope="scope">
                        {{ scope.row.firstName }} {{ scope.row.lastName }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('admin.reqList.companyName')" prop="companyName" width="125"/>
                <el-table-column prop="title" :label="$t('admin.reqList.title')" width="100"/>
                <el-table-column prop="email" label="E-mail" width="190"/>
                <el-table-column prop="phone" :label="$t('admin.reqList.phone')" width="115"/>
                <!--el-table-column prop="status" :label="$t('admin.reqList.status')" width="125"/-->
                <el-table-column :label="$t('admin.reqList.' + reqListStatus)" width="200"
                                 prop="dateUpdate"
                                 sortable
                                 v-if="reqListStatus !== 'pending'">
                    <template slot-scope="scope">
                        <div class="date">{{ scope.row.updateDate}}</div>
                        <div class="user" v-if="(scope.row.approveUser || {}).fullName">
                            {{ scope.row.approveUser.fullName}} ({{scope.row.approveUser.username}})
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="comment" :label="$t('admin.reqList.comment')"/>
                <el-table-column :label="$t('actions')" align="center" width="90">
                    <template slot-scope="scope">
                        <el-tooltip effect="light" v-if="scope.row.status !== 'approved'"
                                    :content="$t('admin.reqList.approve')">
                            <el-button type="success" plain @click="handleProcReqUser('approve', scope.row)">
                                <i class="far fa-check"></i>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip effect="light" v-if="!['declined','approved'].includes(scope.row.status)"
                                    :content="$t('admin.reqList.decline')">
                            <el-button type="danger" plain @click="handleProcReqUser('decline', scope.row)">
                                <i class="far fa-times"></i>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <footer class="footer">
                <el-button type="primary" plain @click="handleCloseReqList()">Close</el-button>
            </footer>

        </el-drawer>
        <edit-user v-if="show.editUser" :id="editUserId" :close="() => {this.show.editUser = false}"
                   :companyName="companyName" :reqListUser="reqListUser"
                   :load-users="loadUsers"/>

    </div>
</template>

<script>
    import _ from 'lodash';
    import Utils from '@/commons/utils'
    import {Users} from '../../models/Users';
    import EditUser from './UserEditor';
    import i18n from '@/commons/i18n';
    import axios from "axios";
    import UserGenerateAuthToken from './actions/UserGenerateAuthToken';

    export default {
        name: 'Users',
        components: {
            EditUser,
        },
        data() {
            return {
                UsersModel: new Users(),
                userSearchString: '',
                status: 'ENABLED',
                rtCompany: {},
                statuses: ['ENABLED', 'LOCKED'],
                exportLoading: false,
                pageSize: 200000,
                show: {
                    editUser: false,
                    showReqList: false
                },
                editUserId: null,
                actions: [{
                    id: 'edit',
                    name: this.$t('edit')
                }, {
                    id: 'generateAuthToken',
                    name: this.$t('admin.users.generateAuthToken.title'),
                    access: (user) => {
                        return this.user.roles.includes('ADMIN') || this.user.username === user.username;
                    }
                }, {
                    id: 'resetAuthTokens',
                    name: this.$t('admin.users.resetAuthTokens.title'),
                    access: (user) => {
                        return this.user.roles.includes('ADMIN') || this.user.username === user.username;
                    }
                }],
                companies: [],
                reqList: [],
                reqListStatus: 'pending',
                companyName: {},
                reqListUser: {},
                newCompany: {
                    name: ''
                }
            };
        },
        computed: {
            searchList() {
                let _input = this.userSearchString.toLowerCase();
                if (_input.length) {
                    return this.UsersModel.fullList.filter(user => {
                        let result = false;
                        if (user.fullName.toLowerCase().includes(_input) || user.username.toLowerCase().includes(_input) || (user.company && user.company.name.toLowerCase().includes(_input))) {
                            result = true;
                        }
                        return result;
                    });
                } else {
                    return this.UsersModel.list;
                }
            },
            currentPage() {
                return (this.UsersModel.offset / this.UsersModel.count) + 1;
            },
            user() {
                return this.$store.state.userInfo;
            }
        },
        created() {
            this.loadUsers();
            this.getCompanies();
            this.getReqList();


        },
        destroyed() {
            this.UsersModel.clear();
        },
        methods: {
            loadUsers(offset = 0) {
                this.$services.security.users({
                    offset: offset,
                    states: [this.status],
                    count: this.pageSize,
                    order: [{field: 'username', type: 'ASC'}]
                }).prepare().then(resp => {
                    this.UsersModel.fill(resp.data.list);
                    // i18n.locale = 'ru';
                    // console.log('i18NN', i18n.locale);
                });
            },
            editUser(id = null) {
                this.editUserId = id;
                this.show.editUser = true;
            },
            lockUser(id, state) {
                let _state = state === 'LOCKED' ? 'ENABLED' : 'LOCKED',
                    _txt = state === 'LOCKED' ? 'Unlock' : 'Lock';
                this.$confirm(_txt + " this user?", this.$t('warning'))
                    .then(() => {
                        this.$services.security.saveUser({
                            user: {
                                id: id,
                                state: _state,
                            },
                            updateDateAccountExpire: state === 'LOCKED'
                        }).prepare().then(() => {
                            this.loadUsers();
                            this.$message({
                                message: 'User locked',
                                type: 'success'
                            });
                        });
                    })
                    .catch(() => {
                    })
                ;
            },
            getCompanies() {
                let _query = {
                    "fullness": "FULL",
                    "deleted": false,
                    "order": [
                        {
                            "field": "name",
                            "type": "ASC"
                        }
                    ],
                    "offset": 0,
                    "count": 8888
                };
                this.$services.companies.list(_query).then(resp => {
                    this.companies = resp.data.list;
                    this.companies.forEach(it => {
                        this.companyName[it.id] = it.name;
                    })
                    this.rtCompany = this.companies.find(it => {
                        return (it.name || '').toLowerCase().includes('ростелеком')
                    })
                    this.$store.commit('updateState', {
                        path: 'rtCompany',
                        data: this.rtCompany
                    })
                    console.log('RT Company ::: ', this.rtCompany)

                });
            },

            onChangePage(page) {
                this.UsersModel.offset = this.UsersModel.count * (page - 1);
            },
            handleActionCommand({action, user}) {
                switch (action.id) {
                    case 'edit':
                        this.editUser(user.id);
                        break;
                    case 'generateAuthToken':
                        this.$modal.dialog(UserGenerateAuthToken, {
                            title: this.$t('admin.users.generateAuthToken.title'),
                            size: 'sm',
                            params: {user: user},
                            buttons: ['close', {
                                id: 'apply',
                                name: this.$t('apply'),
                                type: 'primary',
                                handler: data => {
                                    data.component.save().then(resp => {
                                        data.dialog.close();
                                    });
                                }
                            }]
                        });
                        break;
                    case 'resetAuthTokens':
                        this.$confirm(this.$t('admin.users.resetAuthTokens.msg.confirm', {name: user.fullName}), this.$t('warning'), {
                            type: 'warning', dangerouslyUseHTMLString: true
                        }).then(() => {
                            this.$services.security.resetAuthTokens([user.username]).prepare().then(() => {
                                this.loadUsers();
                            });
                        }, () => {
                        });
                        break;
                    default:
                        console.error(`Action "${id}" handler not found`);
                        break;
                }
            },
            handleInfinite(state) {
                let total = this.result.total;
                if (total > this.pageSize && total > this.result.list.length) {
                    this.refresh({offset: this.result.offset + this.pageSize}).then(data => {
                        if (data.list.length) {
                            state.loaded();
                        } else {
                            state.complete();
                        }
                    }, () => {
                        state.complete();
                    });
                } else {
                    state.complete();
                }
            },
            handleExportUsers() {
                let _query = {},
                    fileName = 'Teqviser_Users.xlsx';
                this.exportLoading = true;
                axios({
                    url: this.$store.state.apiUrl + '/security/userExport',
                    method: 'POST',
                    data: JSON.stringify(_query),
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json;charset=UTF-8'}
                }).then((resp) => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(resp.data);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setTimeout(() => {
                        this.exportLoading = false;
                    }, 1111);
                })

            },
            handleRowDblclick(row, column, event) {
                this.editUser(row.id);
            },
            handleShowReqList() {
                this.show.showReqList = true;
            },
            handleCloseReqList() {
                this.reqListStatus = 'pending';
                this.getReqList();
                this.show.showReqList = false;
            },
            getReqList() {
                this.loadingReqList = true;
                this.$services.demo.list({
                    "offset": 0,
                    "count": 10,
                    "status": [this.reqListStatus]
                }).prepare().then(resp => {
                    this.reqList = resp.data.list.map(it => {
                        it.date = moment(it.dateCreate).format('DD.MM.YYYY HH:mm')
                        it.updateDate = moment(it.dateUpdate).format('DD.MM.YYYY HH:mm')
                        return it
                    });
                    this.loadingReqList = false;
                });

            },
            handleProcReqUser(status, u) {
                let user = {}
                if (status === 'approve') {
                    if (u.email.match(/@rt.ru$/i) && this.rtCompany.id) {
                        user.company = this.rtCompany.id
                    }
                    user.username = u.email.split('@')[0];
                    user.fullName = u.firstName ? (u.firstName + ' ' + u.lastName) : u.name;
                    user.email = u.email;
                    user.state = 'ENABLED';
                    user.reqId = u.id;

                    this.reqListUser = user;
                    this.show.editUser = true;

                } else if (status === 'decline') {
                    this.$services.demo.decline(u.id).prepare().then(resp => {
                        this.getReqList();
                    });
                }
                console.log('user-status', status, user);

            },
            dateAccountExpire(user, column, prop, rowIndex) {
                // return Utils.dates.format(prop, 'DD MMMM YYYY')
                return user.dateAccountExpire ? moment(user.dateAccountExpire).format('DD MMM YYYY') : '';
            },
            dateCreate(user, column, prop, rowIndex) {
                // return Utils.dates.format(prop, 'DD MMMM YYYY')
                return user.dateCreate ? moment(user.dateCreate).format('DD MMM YYYY') : '';
            }
        },
    }
</script>

<style lang="scss">
    @import "../../assets/variables.scss";

    .fst-Users {
        .admin-content {
            max-width: 1400px;
            max-height: calc(100vh - 225px);
        }

        .markIt {
            font-weight: 700;
            color: #222;
            background: none;
        }

        .users-table {
            .el-table__body-wrapper {
                .el-table__row {
                    cursor: pointer;
                }
            }
        }

        .action-panel {
            margin-bottom: 0;
        }

        .user-pagination {
            margin: 10px -9px 0 0;
        }

        .companiesToEdit {
            padding: 20px;
            max-height: 75vh;
            overflow-y: auto;

            .el-input-group__append, .el-input-group__prepend {
                font-variant: all-small-caps;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    #app {
        .el-table {
            .cell {
                line-height: 1.25;
            }
        }
        td{
            vertical-align: top;
        }
        td.el-table_1_column_3 .cell {
            white-space: nowrap;
        }
    }
</style>

<style lang="scss">

    .reqList {
        .el-table {
            .el-button {
                height: auto;
                font-size: 12px;
                padding: 3px 5px;
            }

            th {
                text-transform: capitalize;
            }
        }

        .el-drawer__header {
            font-size: 1.2rem;
            font-weight: 500;
            padding: 15px 20px;
            margin-bottom: 0;
            background: #eaf0fe;
            color: #333;
        }

        .el-drawer__body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            gap: 15px;
            max-height: calc(100vh - 90px);
            overflow: hidden auto;

            footer {
                text-align: right;
            }
        }
    }
</style>