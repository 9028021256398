<template>
    <div class="fst-Summarize">
        <el-drawer
                title="Summarize"
                :close-on-press-escape="false"
                :visible.sync="showSummarize"
                :with-header="false"
                :wrapperClosable="false"
                size="50%"
                direction="ltr">
            <div class="drawer-container">
                <div class="header">
                    <div class="title">
                        <i class="far fa-sigma mr-1 iq-text-blue"></i> Summarize
                    </div>
                    <div>
                        <i class="far fa-times close" @click="closeMe"></i>
                    </div>

                </div>
                <div class="body">
                    <div class="predicate-block">
                        <div class="lang-button flex-1">
                            <el-input
                                    placeholder="Predicate"
                                    clearable
                                    v-model="localFilter.query"
                            ></el-input>
                            <el-input
                                    placeholder="Insert valid JSON"
                                    class="predicate"
                                    clearable
                                    type="textarea"
                                    v-model="jsonCode"
                            ></el-input>
                        </div>
                        <div class="lang-button">
                            <el-select v-model="lang">
                                <el-option value="en" label="English"></el-option>
                                <el-option value="ru" label="Русский"></el-option>
                            </el-select>
                            <el-button @click="search"><i class="far fa-search"></i></el-button>
                        </div>
                    </div>
                    <div class="tab-content">
                        <template v-if="!error">
                            <div class="result" v-html="result.text"></div>
                        </template>
                        <template v-if="error">
                            <div class="noResult">
                                <img src="noResult.svg">
                                <div class="text" v-html="error"></div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="footer">
                    <div></div>
                    <el-button type="primary" @click="closeMe">Close</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import SearchList from './SearchList.vue';
    import _ from "lodash";
    import uuid from "uuid";


    export default {
        name: "Summarize",
        props: {
            showSummarize: {
                type: Boolean,
                default: false
            },
            userInfo: {
                type: Object
            },
            searchFilter: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                jsonCode: '{"key": "value"}',
                result: {},
                error: '',
                lang: 'en',
                localFilter: {}
            };
        },
        computed: {
            activeTab() {
                return this.tabs.find(tab => tab.id === this.defaultActiveTab);
            },
        },
        mounted() {
            // console.log('uI:', this.userInfo);
            this.localFilter = _.cloneDeep(this.searchFilter)
        },
        methods: {
            search() {
                if (this.jsonCode && this.$utils.isValidJson(this.jsonCode)) {
                    let filter = {
                        "language": this.lang, // en/ru
                        "filter": this.localFilter, // поисковый фильтр
                        "params": JSON.parse(this.jsonCode) // дополнительные параметры
                    }
                    this.$services.documents.summarize(filter).prepare().then(resp => {
                        if (resp.data) {
                            this.result = resp.data;
                            this.error = '';
                            this.result.text = this.result.text.replace(/\\n/g , "\n");
                            // console.log('----- respData: ', this.result);
                        } else {
                            this.error = 'Empty result';
                            this.result = null;
                        }
                    }, error => {

                    });
                    this.error = '';
                } else {
                    this.error = 'Not valid JSON!'
                }
            },
            getUserTagList() {
                this.$services.tags.list({
                    count: 500,
                    offset: 0,
                    userIds: [this.userInfo.id]
                }).then(resp => {
                    if (resp && resp.data) {
                        this.fullUserTagList = resp.data.list;
                        // this.refreshAssignTagsList();
                    }
                });
            },
            closeMe() {
                this.jsonCode = this.error = this.result = '';
                this.$emit('closeSummarize');
            },
            handleToggleFavorites(doc) {
                let docTab = this.tabs.find(tab => tab.id === doc.type);
                let incrementToggle = (val) => {
                    this.favoritesTab.filterData.types.forEach(item => {
                        if (item.type === doc.type) {
                            if (val) {
                                item.inFavorites = parseInt(item.inFavorites) + 1;
                            } else {
                                item.inFavorites = parseInt(item.inFavorites) - 1;
                            }
                        }
                    });
                };
                docTab.result.list.forEach(item => {
                    if (item.id === doc.id) {
                        item.isInFavorite = doc.isInFavorite;
                    }
                });

                if (doc.isInFavorite) {
                    incrementToggle(true);
                    this.favoritesTab.result.total++;
                } else {
                    incrementToggle(false);
                    this.favoritesTab.result.total--;
                }

                this.favoritesTab.filterData = _.cloneDeep(this.favoritesTab.filterData);
                docTab.result = _.cloneDeep(docTab.result);
            },

        }
    };
</script>

<style lang="scss">
    @import "../../assets/variables.scss";

    .fst-Summarize {
        .drawer-container {
            .body {
                max-height: none;
                overflow-y: hidden;

                > .tab-content {
                    max-height: calc(100vh - 315px);
                    overflow: auto;
                    margin-right: -10px;
                    padding-right: 10px;
                }
            }
        }

        .tab-content {
            width: 100%;
            margin-top: 30px;
        }

        .predicate-block {
            display: flex;
            gap: 15px;
            margin-bottom: 0;


            .predicate {
                textarea {
                    height: 70px;
                    font-size: 12px;
                    font-family: monospace, sans-serif;
                }
            }

            .el-button {
                border: none;
                background: $iq-color-blue;
                color: #ffffff;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                font-size: 24px;
                flex: 1;
            }

            .lang-button {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }

    }
</style>

<style lang="scss" scoped>
    .result {
        background: #ffffff;
        border: 1px solid #D7E1FC;
        border-radius: 5px;
        padding: 10px 15px;
        font-family: monospace, sans-serif;
        white-space: pre-line;
        word-break: break-word;
        font-size: .8rem;
        line-height: 1.4;
    }

    .code {
        margin-top: 15px;
        white-space: pre-line;
        word-break: break-word;
        font-size: 12px;
    }
</style>
