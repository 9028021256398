<template>
    <section class="fst-UnfriendlyCountries">
        <div class="wrap admin-content">
            <div class="control">
                <el-input v-model="predicate" placeholder="Search country" a prefix-icon="far fa-search"
                          clearable></el-input>
                <el-radio-group v-model="filter.frUnfr" class="mt-2" size="mini">
                    <el-radio-button label="">All</el-radio-button>
                    <el-radio-button label="unfriendly">Only unfriendly</el-radio-button>
                    <el-radio-button label="friendly">Only friendly</el-radio-button>
                </el-radio-group>
            </div>
            <div class="tabData" v-loading="loading">
                <table>
                    <tr>
                        <th></th>
                        <th>Country</th>
                        <th>is Unfriendly</th>
                    </tr>
                    <tr v-for="(item, key) in countries.filter(countryFilter(item))"
                        :key="key">
                        <td>
                            <flag :iso="$utils.countryToISO((item.id || '').toLowerCase())" :squared="false"
                                  class="mr-1">
                            </flag>
                        </td>
                        <td class="capitalize">
                            {{item.id}}
                        </td>
                        <td class="text-center">
                            <el-checkbox
                                    @change="handleCheck(item)"
                                    :value="item.alignment === 'unfriendly'"></el-checkbox>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "UnfriendlyCountries",
        data() {
            return {
                countries: [],
                predicate: '',
                filter:{
                    frUnfr: ''
                },
                loading: true,
            }
        },
        mounted() {
            this.getCountries();
        },
        methods: {
            getCountries() {
                this.$services.country.alignments().then(resp => {
                    this.countries = resp.data;
                    this.loading = false;
                });
            },
            handleCheck(item){
                item.alignment = item.alignment === 'unfriendly' ? 'friendly' : 'unfriendly'
                this.loading = true;
                this.$services.country.saveAlignment(item).then(resp => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500)
                });
            },
            countryFilter(it){
                return it => (!this.predicate || it.id.includes(this.predicate))
                    && (!this.filter.frUnfr || it.alignment === this.filter.frUnfr)
            }

        }
    }
</script>

<style lang="scss">
    .fst-UnfriendlyCountries {
        .wrap {
            max-width: 650px;
        }
        .admin-content{
            max-height: calc(100vh - 155px);
        }

        .control {
            margin-bottom: 15px;
            padding-bottom: 10px;
        }
        .tabData{
            max-height: calc(100vh - 250px);
            overflow-y: auto;
            padding-right: 10px;
        }
        table {
            td, th {
                padding: 7px 3px;
            }

            th {
                text-align: left;
            }
        }
    }
</style>