<template>
    <section class="fst-SummarizationSettings">
        <div class="admin-content" v-loading="loading">
            <div class="control">
                <h3 class="m-0">Summarization Settings</h3>
                <div>
                    <el-button type="primary" plain @click="refresh">
                        <i class="fal fa-sync mr-2"></i>
                        Refresh
                    </el-button>
                    <el-button type="primary" @click="save"
                               :disabled="saveDisabled">
                        <i class="fal fa-save mr-2"></i>
                        Save
                    </el-button>
                </div>
            </div>
            <div class="result">
                <div class="source" v-for="(src, type) in sources"
                     :class="{error: !validJson[type].isValid}"
                     :key="type">
                    <div class="title">{{type}}</div>
                    <el-input v-model="src.params" type="textarea"
                              placeholder="Input valid JSON"
                              rows="9"></el-input>
                    <div class="error-msg" v-if="!validJson[type].isValid">Invalid JSON</div>
                </div>
                <div class="note">
                    <h4>Valid JSON - use double quotes <i class="fas ml-1 fa-quote-right"></i></h4>
                    <ul>
                        <li>{"key": "value"}</li>
                        <li>{key: "value"}</li>
                        <li>null</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SummarizationSettings",
        data() {
            return {
                loading: false,
                sources: {
                    Science: {
                        params: ''
                    },
                    ScienceOpen: {
                        params: ''
                    },
                    ScienceOpenAlex: {
                        params: ''
                    },
                    ScienceCrossref: {
                        params: ''
                    },
                    Patents: {
                        params: ''
                    },
                    News: {
                        params: ''
                    }
                }
            }
        },
        computed: {
            validJson() {
                return _.pickBy(this.sources, (it, type) => {
                    it.isValid = this.$utils.isValidJson(this.fixJson(it.params))
                    return it
                })
            },
            saveDisabled() {
                return _.some(this.validJson, it => !it.isValid)
            }
        },
        created() {
            this.refresh();
        },
        methods: {
            refresh() {
                this.loading = true;
                this.$services.summary.settings().then(resp => {
                    _.merge(this.sources, _.pickBy(resp.data, src => {
                        src.params = JSON.stringify(src.params, null, 3) || ''
                        return src;
                    }))
                    setTimeout(() => {
                        this.loading = false;
                    }, 500)

                });
            },

            save() {
                this.loading = true;
                let result = _.pickBy(_.cloneDeep(this.sources), it => {
                    it.params = JSON.parse(this.fixJson(it.params));
                    delete it.isValid;
                    return it;
                });
                // console.log('Saving: ', result)
                this.$services.summary.saveSettings(result).then(resp => {
                    this.refresh();
                });
            },
            fixJson(str = '') {
                return str ? str.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ') : 'null'
            }
        }
    }
</script>

<style lang="scss">
    @import '../../assets/variables';

    .fst-SummarizationSettings {
        max-width: 1200px;

        .control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: $border;
            padding-bottom: 15px;
        }

        .result {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 15px 0;
            gap: 15px;

            > div {
                flex-basis: 48.5%;
            }

            .title {
                font-weight: 500;
                margin-bottom: 3px;
                color: $iq-color-text-secondary;
            }

            .error {
                .el-textarea__inner {
                    border: 2px solid #d75050;
                }

                .error-msg {
                    background: #d75050;
                    color: #fff;
                    padding: 2px 6px;
                    font-size: 13px;
                    border-radius: 0 0 4px 4px;
                    top: -2px;
                    position: relative;
                    display: inline-block;
                }
            }
        }
    }

</style>