f<template>
    <div class="techRating">
        <div class="wrapper"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">

            <div class="filter-block" :class="{'mb-35': isCountry}">
                <div class="rating-filter">
                    <div class="legend">
                        <div v-for="(item,key) in docTypes"
                             @click="handleToggleDocType(item)"
                             :key="key"
                             :class="{disabled: !item.active}"
                             :style="`background-color: ${item.color}`">{{ $t(item.title) }}
                        </div>
                    </div>
                    <div class="flex-center">
                        <div class="mr-2">
                            {{$t('label.period')}} {{$t('label.from')}}
                            <el-select v-model="filter.dateFrom"
                                       style="width: 63px;"
                                       popper-class="darkSelect"
                                       @change="apply({clear:'trendPredicate'})">
                                <el-option v-for="year in years"
                                           :key="`from-${year.value}`"
                                           :label="year.value"
                                           :value="year.value">
                                    {{ year.value }}
                                </el-option>
                            </el-select>

                        </div>
                        <div class="mr-50">
                            {{$t('label.to')}}
                            <el-select v-model="filter.dateTo"
                                       style="width: 63px;"
                                       popper-class="darkSelect"
                                       @change="apply({clear:'trendPredicate'})">
                                <el-option v-for="year in years"
                                           :key="`to-${year.value}`"
                                           :label="year.value"
                                           :value="year.value"
                                           :disabled="year.disabled">
                                    {{ year.value }}
                                </el-option>
                            </el-select>
                            <div class="filter-warning"><i
                                    class="fas fa-exclamation-triangle mr-1" v-if="dateWarning"></i>{{ dateWarning }}
                            </div>
                        </div>
                        <div class="mr-20" v-show="filter.type !== 'cagr'">
                            {{ $t('dashboard.compareTo') }}
                            <el-select v-model="filter.compareTo"
                                       style="width: 125px;"
                                       popper-class="darkSelect"
                                       @change="apply({clear:'trendPredicate'})">
                                <el-option v-for="item in comparePeriods"
                                           :key="item.value"
                                           :value="item.value"
                                           :label="`${item.name} (${filter.dateFrom - item.value})`">

                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-show="filter.type === 'cagr'" class="mr-20">
                        {{ $t('dashboard.mean') }}<select v-model="filter.mean" @change="apply({})">
                        <option v-for="item in meanVals" :key="item.value"
                                :value="item.value">
                            {{ $t('dashboard.' + item.label) }}
                        </option>
                    </select>
                    </div>
                    <el-radio-group class="mr-20" v-model="filter.type" @change="handleSelectType">
                        <el-radio-button label="absolute">{{ $t('dashboard.Absolute') }}</el-radio-button>
                        <el-radio-button label="cagr">CAGR</el-radio-button>
                    </el-radio-group>
                </div>
                <span class="dashboardExport" @click="handleExport">
                    <i class="fas fa-pulse fa-spinner" v-if="exportLoading"></i>
                    <svg v-else width="16" height="19" viewBox="0 0 16 19" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z"
                              fill="currentColor"/>
                    </svg>
                    {{ $t('label.exportToExcel') }}
                </span>
            </div>
            <div v-if="isCountry" class="onlyTrends">
                <label @click="handleOnlyTrends" :class="{active: onlyTrends}">
                    <i class="fa mr-1" :class="onlyTrends ? 'fa-check-square' : 'fa-square'"></i>
                    {{$t('dashboard.onlyTrends')}}
                </label>
            </div>

            <div class="dashboard-content">
                <div v-show="fullData && fullData.length">
                    <div class="chart-subfilter-block">
                        <div class="textSearch" :class="{'focus': trendPredicate.length}">
                            <el-autocomplete
                                    clearable
                                    class="inline-input"
                                    v-model="trendPredicate"
                                    ref="autocomplete"
                                    @select="handleFilterTrend()"
                                    @clear="handleFilterTrend()"
                                    size="mini" prefix-icon="far fa-search"
                                    :fetch-suggestions="textSearchSuggest"
                                    :trigger-on-focus="false"
                                    :select-when-unmatched="true">
                            </el-autocomplete>
                        </div>
                        <div class="xAxis">
                            <span v-for="(item,key) in xAxisVals" :key="key">{{ item }}</span>
                        </div>
                        <div class="sortBy">
                            <div class="byScore">
                                <template v-if="filter.type !== 'cagr'">
                                    <i class="fas fa-long-arrow-down" @click="handleSortBy('score', 'desc')"
                                       :class="{'active': sortBy.field === 'score' && sortBy.dir === 'desc'}"></i>
                                    <i class="fas fa-long-arrow-up" @click="handleSortBy('score', 'asc')"
                                       :class="{'active': sortBy.field === 'score' && sortBy.dir === 'asc'}"></i>
                                </template>
                                <template v-else>
                                    <i class="fas fa-long-arrow-down" @click="handleSortBy(filter.mean, 'desc')"
                                       :class="{'active': sortBy.field === filter.mean && sortBy.dir === 'desc'}"></i>
                                    <i class="fas fa-long-arrow-up" @click="handleSortBy(filter.mean, 'asc')"
                                       :class="{'active': sortBy.field === filter.mean && sortBy.dir === 'asc'}"></i>

                                </template>
                            </div>
                            <div class="byDynamic" v-show="filter.type !== 'cagr'">
                                <i class="fas fa-long-arrow-down" @click="handleSortBy('dynamic', 'desc')"
                                   :class="{'active': sortBy.field === 'dynamic' && sortBy.dir === 'desc'}"></i>
                                <i class="fas fa-long-arrow-up" @click="handleSortBy('dynamic', 'asc')"
                                   :class="{'active': sortBy.field === 'dynamic' && sortBy.dir === 'asc'}"></i>

                            </div>
                        </div>

                    </div>
                    <div class="chart-block" ref="chartBlock">
                        <div class="chart-container">
                            <apexchart
                                    ref="ratingChart"
                                    :options="columnsChartOptions"
                                    :series="columnsSeries"
                                    @dataPointSelection="dataPointSelectionHandler"
                                    @updated="handleUpdated">
                            </apexchart>
                        </div>
                        <div class="changes" ref="changes">
                            <div class="change" :class="[item.class, {top: item.top}, {newTrend:item.newTrend}]"
                                 v-for="(item,key) in statuses"
                                 :key="key">
                                <div class="sum" v-html="item.sum"></div>
                                <div v-if="filter.type !== 'cagr'" class="d-flex align-items-center">
                                    <div class="rate"><i :class="['fas', `${item.icon}`]" v-if="item.icon"></i>{{
                                        item.rate
                                        }}
                                    </div>
                                    <div class="label">
                                        {{ item.label }}
                                    </div>
                                </div>
                            </div>
                            <infinite-loading ref="infiniteLoading" @infinite="handleInfinite">
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>
                        </div>
                    </div>

                    <!--div class="text-center">
                        <el-button type="primary" @click="handleShowMore()" v-show="showShowMore">Show more</el-button>
                    </div-->
                </div>
                <div v-if="!fullData || fullData.length === 0" class="noResult"><h3>No result</h3></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import axios from "axios";


    export default {
        name: "TechnologyRating",
        props: {
            filterQuery: String,
            incEvent: Object,
            queryFields: Object,
            isNativeQuery: Boolean,
            params: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                predicateValue: '',
                onlyTrends: true,
                logarithmicAxis: false,
                showShowMore: true,
                exportLoading: false,
                dateWarning: '',
                query: {},
                filter: {
                    query: '',
                    type: 'absolute',
                    dateFrom: (new Date().getFullYear() - 1).toString(),
                    dateTo: (new Date().getFullYear()).toString(),
                    compareTo: '1',
                    mean: 'growthMeanA', // среднее арифм || геометрическое - 'geometric'
                },
                sortBy: {
                    field: 'score',
                    dir: 'desc'
                },
                meanVals: [
                    {label: 'Arithmetic', value: 'growthMeanA'},
                    {label: 'Geometric', value: 'growthMeanG'},
                ],
                trendPredicate: '',
                lastDateTo: new Date().getFullYear(),
                curYear: new Date().getFullYear(),
                tmp: {},
                minYear: 2015,
                count: 25,
                offset: 15,
                series: {},
                years: [],
                xAxisVals: [0, 1, 2, 3],
                docTypes: [
                    {
                        name: 'Science',
                        title: 'Science',
                        color: '#EF7686',
                        // color: '#F9B755',
                        active: true
                    },
                    {
                        name: 'Patents',
                        title: 'Patents',
                        color: '#F1B050',
                        // color: '#00BF52',
                        // color: '#F1B050',
                        active: true
                    },
                    {
                        name: 'Investment',
                        title: 'Investments',
                        color: '#73DE7C',
                        // color: '#d54444',
                        // color: '#eb5266',
                        active: true
                    },
                    {
                        name: 'News',
                        title: 'News',
                        color: '#A1BAFF',
                        // color: '#4474F8',
                        // color: '#7ba3ff',
                        active: true,
                    },
                ],
                comparePeriods: [
                    {name: '1 year', value: '1'},
                    {name: '2 years', value: '2'},
                    {name: '3 years', value: '3'}
                ],
                periods: {
                    dates: {
                        from: '',
                        to: ''
                    },
                    compareTo: {
                        from: '',
                        to: ''
                    }
                },
                loading: false,
                statuses: [],
                columnsSeries: [{
                    name: 'Technology rating',
                    data: []
                }],
                fullData: [],
                columnsChartOptions: {
                    colors: ["#0A0", "#FFF", "#00A", "#A00"],
                    chart: {
                        height: '4000px',
                        type: 'bar',
                        foreColor: '#C0C5CB',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '30%',
                            // distributed: true,
                            // borderRadius: 10
                        },
                    },
                    fill: {
                        opacity: 0.8,
                    },
                    stroke: {
                        width: 1,
                        colors: ['#191919'],
                        // curve: 'smooth'
                        curve: 'stepline'

                    },
                    tooltip: {
                        enabled: true,
                        followCursor: true,
                    },
                    grid: {
                        borderColor: '#262626',
                        // strokeDashArray: 2,
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },
                    legend: {
                        show: false,
                        position: 'top',
                        horizontalAlign: 'center'
                    },
                    dataLabels: {
                        enabled: false,
                        enabledOnSeries: [0],
                        textAnchor: 'start',
                        formatter: function (val, opt) {
                            let sum = 0;
                            _.each(opt.w.globals.series, it => {
                                sum += parseFloat(it[opt.dataPointIndex]);
                            })
                            return sum.toFixed(2);
                        },
                        dropShadow: {
                            enabled: true
                        },
                        style: {
                            colors: ['#fff'],
                            fontWeight: 'bold',
                            fontSize: 15
                        },
                    },
                    yaxis: {
                        max: 3,
                        min: 0,
                        labels: {
                            show: true,
                            align: 'right',
                        },
                    },
                    xaxis: {
                        // color: '#C0C5CB',
                        type: 'category',
                        // position: 'bottom',
                        axisBorder: true,
                        lines: {
                            show: false,
                        },
                        style: {
                            fontSize: '15px'
                        }

                    },
                },
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            isCountry() {
                return this.params.isCountryRating
            },
            srvPrefix() {
                return this.params.isCountryRating ? 'country' : 'trend'
            }
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            }
        },
        mounted() {
            for (let year = this.minYear; year <= this.curYear; year++) {
                this.years.push({value: year, disabled: false});
            }
            this.docTypes = this.docTypes.filter(it => {
                return !this.isCountry || it.name !== 'News'
            })
            this.resetInfinite();
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let ii = 0;
                self.periods = {
                    dates: {
                        from: self.filter.dateFrom + '-01-01',
                        to: parseInt(self.filter.dateTo) + 1 + '-01-01'
                    },
                    compareTo: {
                        from: parseInt(self.filter.dateFrom) - parseInt(self.filter.compareTo) + '-01-01',
                        to: parseInt(self.filter.dateFrom) + '-01-01'
                    }
                };
                let _query = {
                    filter: {
                        query: self.filterQuery,
                        types: _.map(self.docTypes.filter(it => it.active), 'name'),
                        duplicate: false,
                        queryFields: self.queryFields.value,
                        spam: false,
                        subfilters: [
                            {
                                operator: 'AND_NOT',
                                hostingGroups: {
                                    excludeFromSearch: true
                                }
                            }
                        ]
                    },
                    period: {
                        "minBoundType": "CLOSED",
                        "maxBoundType": "OPEN",
                        "min": self.periods.dates.from,
                        "max": self.periods.dates.to
                    },
                    dynamicPeriod: {
                        "minBoundType": "CLOSED",
                        "maxBoundType": "OPEN",
                        "min": self.periods.compareTo.from,
                        "max": self.periods.compareTo.to
                    }
                }
                if (this.isCountry) {
                    _query.trendsOnly = this.onlyTrends;
                }
                if (this.isNativeQuery) { // --- if native Query is ON ---
                    _query.filter.nativeQuery = {
                        value: _query.filter.query
                    };
                    delete _query.filter.query;
                }

                // console.log('Rating Filter', _query);
                self.query = _query;
                self.loading = true;
                self.statuses = [];

                if (this.filter.type === 'absolute') { // --- Absolute ---
                    this.$services.analytics[this.srvPrefix + 'Rank'](_query).then(resp => {
                        self.fullData = resp.data.items || [];
                        // self.fullData = resp.data.trends.filter(it => typeof it.dynamic !== 'undefined') || [];
                        self.createSeries(self.count);
                    });
                } else { // --- CAGR ---
                    delete _query.dynamicPeriod;
                    this.$services.analytics[this.srvPrefix + 'Growth'](_query).then(resp => {
                        self.fullData = resp.data.items || [];
                        self.createSeries(self.count, {type: 'cagr'});
                    });
                }
            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this;
                let keyword = self.columnsSeries[config.seriesIndex].data[config.dataPointIndex].value,
                    docType = self.docTypes.filter(it => it.active)[config.seriesIndex].name;
                // keyword = self.columnsSeries.data[keyIndex].x;
                // console.log('conf', config );
                // console.log('query--', keyword, docType, self.filterQuery);
                let years = [],
                    yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR';
                for (let year = self.filter.dateFrom; year <= self.filter.dateTo; year++) {
                    years.push(year.toString());
                }

                let facets = {
                    requests: [
                        {
                            type: yearType,
                            values: years
                        }, {
                            type: self.isCountry ? "COUNTRIES" : "CUSTOM",
                            values: [keyword.toLowerCase()],
                            facetId: self.isCountry ? null : 'trend'
                        }
                    ]
                }
                let params = {
                    query: self.filterQuery,
                    isNativeQuery: this.isNativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                if (e.button === 0) {
                    self.$utils.openSearch(params);
                }
            },
            createSeries(count, params = {}) {
                let self = this;
                let _data = [];
                let isCAGR = self.filter.type === 'cagr';
                let maxRate, minRate, maxY;
                let categories = self.docTypes.filter(it => it.active);
                this.loading = true;
                if (params.filterBy && params.value) {
                    _data = self.fullData.filter(it => (it[params.filterBy] || '').toLowerCase().includes((params.value || '').toLowerCase()));
                } else {
                    _data = self.fullData;
                }
                if (self.trendPredicate) {
                    _data = self.fullData.filter(it => (it.name || '').toLowerCase().includes(self.trendPredicate.toLowerCase()));
                }
                let _sortByField = isCAGR ? self.filter.mean : self.sortBy.field;
                _data = _.orderBy(_data, _sortByField, self.sortBy.dir);
                _data = _data.slice(0, count);
                let total = _data.length;
                this.$refs.changes.style.minHeight = (total * 145 / 3 + 85) + 'px';
                if (isCAGR) {
                    maxY = '';
                } else {
                    maxRate = (_.maxBy(self.fullData, 'dynamic') || {}).dynamic;
                    minRate = (_.minBy(self.fullData, 'dynamic') || {}).dynamic;
                    maxY = (_.maxBy(_data, 'score') || {}).score;
                }

                self.showShowMore = count <= total;
                _.each(categories, it => {
                    self.series[it.name] = {
                        data: [],
                    }
                })
                self.statuses = [];
                let sumArr = [];
                _.each(_data, (it, ind) => {
                    let label = '',
                        newTrend = false,
                        _sum = 0, top = false,
                        icon = '';
                    _.each(categories, src => {
                        let _scores = it.scores || {},
                            _ind = self.trendPredicate ? self.fullData.findIndex(nm => nm.value === it.value) + 1 :(ind+1)  ,
                            _y = isCAGR ? Math.ceil(it.growthRates[src.name] * 100) : self.$utils.roundX(it.scores[src.name], 3)
                        self.series[src.name].data.push({
                            x: _ind + '. ' + (it.value.charAt(0).toUpperCase() + it.value.slice(1) || '').replace(/_/g, ' '),
                            value: it.value,
                            score: _y || self.$utils.roundX(_scores[src.name], 5) || self.$utils.roundX(_scores[src.name], 7) || self.$utils.roundX(_scores[src.name], 9) ,
                            // y: self.logarithmicAxis &&  it.scores[src.name] === 0 ? 0.000000001 : (it.scores[src.name]).toFixed(2)
                            // y: self.logarithmicAxis ? self.log10(it.scores[src.name] * 10000).toFixed(2) : it.scores[src.name].toFixed(2)
                            y: (_scores[src.name] && _scores[src.name] < 0.015) ? 0.015 : _y
                        });
                        if (isCAGR) {
                            _sum += (it.growthRates[src.name] ? parseFloat(it.growthRates[src.name]) : 0);
                        }
                    });
                    // console.log('self.series', self.series);
                    // sumArr.push(_sum === '0.000' ? ' > 0' : _sum);
                    sumArr.push(_sum);
                    if (isCAGR) {
                        let _mean = it[self.filter.mean] * 100;
                        self.statuses.push({
                            sum: (_mean < 0.005 ? ' <span class="mt"> > </span>' : '') + (_mean).toFixed(2) + " %"
                        })
                    } else {
                        if (typeof it.dynamic === 'undefined') {
                            newTrend = true;
                        } else if (it.dynamic && it.dynamic === minRate) {
                            label = "TOP FALL";
                            icon = 'fa-long-arrow-down';
                            top = true;
                        } else if (it.dynamic && it.dynamic === maxRate) {
                            label = "TOP RISE";
                            icon = 'fa-long-arrow-up';
                            top = true;
                        } else if (it.dynamic > 15) {
                            label = "Fast rise";
                            icon = 'fa-long-arrow-up';
                        } else if (it.dynamic < -15) {
                            label = "Fast fall";
                            icon = 'fa-long-arrow-down';
                        }
                        self.statuses.push({
                            rate: (typeof it.dynamic !== 'undefined') ? Math.abs(it.dynamic) : 'new',
                            label: label,
                            newTrend: newTrend,
                            top: top,
                            icon: icon,
                            class: it.dynamic > 0 ? 'up' : it.dynamic < 0 ? 'down' : null,
                            sum: it.score === 0 ? '0.000'
                                : it.score < 0.0005 ? ' <span class="mt"> < </span>0.001'
                                    : it.score.toFixed(3)
                        })
                    }
                });

                self.xAxisVals = [];
                if (isCAGR) {
                    maxY = Math.ceil(_.max(sumArr) * 100);
                }
                self.columnsSeries = categories.map(it => {
                    return {
                        name: it.name,
                        data: self.series[it.name].data,
                        color: it.color
                    }
                })

                // let maxScore = (_.maxBy(self.fullData, 'score') || {}).score;

                self.$nextTick(() => {
                    self.$refs.ratingChart.updateOptions({
                        chart: {
                            height: total * 145 / 3 + 70
                        },
                        yaxis: {
                            max: maxY,
                            forceNiceScale: true,
                            labels: {
                                show: true,
                                align: 'right',
                                minWidth: 250,
                                maxWidth: 250,
                                offsetX: -15,
                                style: {
                                    fontSize: '14px'
                                }
                            },
                            axisTicks: {
                                show: false,
                            }

                        },
                        xaxis: {
                            labels: {
                                show: true,
                                style: {
                                    colors: ['rgba(0,0,0,0)']
                                }
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (val, opt) {
                                    let _val = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].score
                                    // console.log('opts', opt)
                                    return isCAGR ? _val + "%" : _val;
                                },
                            }
                        },
                    })
                    setTimeout(() => {
                        self.xAxisVals = _.map(document.querySelectorAll('.apexcharts-xaxis-texts-g title'), 'innerHTML').reverse();
                        this.loading = false;
                    }, 333);
                })
                // console.log('FullData', self.fullData);
                // console.log('Series::::', self.columnsSeries);
            },
            textSearchSuggest(queryString, cb) {
                let links = this.fullData.map(it => {
                    return {value: it.name}
                });
                let results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
                };
            },
            log10(n) {
                return Math.log(n) / Math.log(10);
            },
            apply(param = {}) {
                let self = this;
                if (param.clear === 'trendPredicate') {
                    self.trendPredicate = '';
                }
                self.$emit('apply');
            },
            handleShowMore() {
                let self = this;
                self.count += self.offset;
                self.loading = true;
                self.createSeries(self.count);
                // self.$refs.ratingChart.updateSeries(self.columnsSeries);
            },
            handleFilterTrend() {
                this.createSeries(this.count);
                this.$refs.autocomplete.$children
                    .find(c => c.$el.className.includes('el-input'))
                    .blur();
                this.$refs.autocomplete.focus();
            },
            handleSortBy(field, dir) {
                this.sortBy = {
                    field: field,
                    dir: dir
                };
                this.createSeries(this.count);
            },
            handleToggleDocType(item) {
                item.active = !item.active;
                this.apply();
            },
            handleSelectType() {
                let _curYear = this.years.find(it => it.value === this.curYear);
                this.sortBy.dir = 'desc';
                if (this.filter.type === 'cagr') {
                    this.sortBy.field = this.filter.mean;
                    _curYear.disabled = true;
                    this.lastDateTo = this.filter.dateTo;
                    if (this.filter.dateTo == this.curYear) {
                        this.filter.dateTo = this.years[this.years.length - 2].value;
                        this.dateWarning = this.$t('dashboard.yearChangedTo') + this.filter.dateTo;
                        clearTimeout(this.tmp.warningStamp);
                        this.tmp.warningStamp = setTimeout(() => {
                            this.dateWarning = ''
                        }, 6886);
                    } else {
                        this.dateWarning = '';
                    }
                } else {
                    this.dateWarning = '';
                    this.sortBy.field = 'score';
                    _curYear.disabled = false;
                    if (this.lastDateTo == this.curYear) {
                        this.filter.dateTo = this.curYear;
                        this.dateWarning = this.$t('dashboard.yearRestoredTo') + this.filter.dateTo;
                        clearTimeout(this.tmp.warningStamp);
                        this.tmp.warningStamp = setTimeout(() => {
                            this.dateWarning = ''
                        }, 6886);
                    } else {
                        this.dateWarning = '';
                    }
                }
                this.apply();
            },
            handleInfinite(state) {
                // console.log('>>>>> Infinity!', state, this.type);
                let self = this,
                    total = this.fullData.length || 0;
                if (total > self.count && total > self.statuses.length) {
                    this.handleShowMore();
                    state.loaded();
                } else {
                    state.complete();
                }
            },
            handleOnlyTrends() {
                this.onlyTrends = !this.onlyTrends;
                this.refresh();
            },
            resetInfinite() {
                // console.log('---------- reset ----------');
                if (this.$refs.chartBlock) this.$refs.chartBlock.scrollTop = 0;
                if (this.$refs.infiniteLoading) {
                    this.$refs.infiniteLoading.stateChanger.reset();
                }
            },
            handleUpdated(item) {
                // console.log('updated: ', item);
            },
            handleOpenSearch(keyword, docType, facets) {
                window.localStorage.setItem('wordStatQuery', JSON.stringify({
                    query: keyword,
                    docType: docType,
                    facets: facets,
                    isNativeQuery: this.isNativeQuery
                }));
                window.open(document.location.origin + this.$store.state.contextUrl + '/', '_blank');
            },
            handleExport() {
                if (!this.exportLoading) {
                    let self = this,
                        _query = _.cloneDeep(self.query),
                        _service = this.srvPrefix + 'RankExport',
                        fileName = 'Teqviser_' + (this.isCountry ? 'Country' : 'Technology') + 'Rating.xlsx';

                    self.exportLoading = true;
                    if (self.filter.type === 'cagr') {
                        _service = this.srvPrefix + 'GrowthExport';
                        _query.meanType = self.filter.mean === 'growthMeanA' ? 'arithmetic' : 'geometric';
                    }

                    axios({
                        url: this.$store.state.apiUrl + '/analytics/' + _service,
                        method: 'POST',
                        data: JSON.stringify(_query),
                        responseType: 'blob',
                        headers: {'Content-Type': 'application/json;charset=UTF-8'}
                    }).then((resp) => {
                        // console.log('Checked query:', query, this.filter);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.data);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.exportLoading = false;
                    })
                }
            },

            handleLogarithmicAxis() {
                let self = this;
                // console.log('self.logarithmicAxis', self.logarithmicAxis);
                // self.columnsChartOptions.yaxis.logarithmic = self.logarithmicAxis;
                // self.loading = true;
                setTimeout(() => {
                    // this.refresh();
                    self.$refs.ratingChart.updateOptions({
                        yaxis: {
                            // max: self.logarithmicAxis ? 12 : 3,
                            // logarithmic: self.logarithmicAxis,
                            labels: {
                                minWidth: 207,
                                maxWidth: 207,
                                style: {
                                    fontSize: '13px'
                                }
                            }
                        }
                    });
                    // self.refresh();
                    // console.log('columnsChartOptions', this.columnsChartOptions);
                }, 210)
            },
        }
    }
</script>

<style lang="scss">
    .techRating {
        .rating-filter {
            display: flex;
            align-items: center;
        }

        .onlyTrends {
            margin: -29px 0 10px 0;
            color: $iq-dark-theme-text;
            font-size: 15px;
            font-weight: 700;
            /*position: absolute;*/
            /*top: 67px;*/
            /*z-index: 3000;*/

            label {
                cursor: pointer;
                padding: 3px;
                &.active{
                    color: #ccc;
                }
            }
        }

        .chart-subfilter-block {
            //z-index: 390;
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: absolute;
            background: $iq-dark-theme-bg-light-1;
            z-index: 450;
            left: 0;

            .textSearch {
                flex-basis: 233px;
                text-align: right;
                position: relative;

                > div {
                    //position: absolute;
                    //right: 7px;
                    //left: auto;
                    //z-index: 400;
                }

                input.el-input__inner {
                    height: 26px;
                    max-width: 0;
                    transition: all .45s ease;
                    padding-left: 35px;
                    padding-right: 0;

                    &:focus, &:not(:empty) {
                        max-width: 300px;
                        padding-left: 30px;
                        padding-right: 30px;

                    }
                }

                .el-input {
                    &:hover {
                        input.el-input__inner {
                            max-width: 300px;
                            padding-left: 30px;
                            padding-right: 30px;
                        }
                    }
                }

                &.focus {
                    input.el-input__inner {
                        max-width: 300px;
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }

                .el-input--small .el-input__icon {
                    line-height: 26px;

                }
            }

            .sortBy {
                display: flex;
                margin: 0 91px 0 18px;
                flex: 0;
                flex-basis: 98px;
                //justify-content: center;

                > div {
                    display: flex;
                    align-items: center;
                }

                i {
                    color: $iq-dark-theme-text;
                    display: block;
                    line-height: 1;
                    margin: -3px 0;
                    padding: 3px;
                    cursor: pointer;

                    &.active {
                        color: #fff;
                    }
                }

                .byScore {
                    //width: 36px;
                    //margin-right: 23px;
                }

                .byDynamic {
                    margin-left: 27px;
                }
            }

            .xAxis {
                flex: 1;
                display: flex;
                justify-content: space-between;
                padding: 5px 0 17px;
                border-bottom: 1px solid $iq-dark-theme-color-border;
                margin: 0 22px 0 30px;
            }


        }

        .legend {
            margin-right: 50px;

            > div {
                margin: 5px 10px 5px 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                border-radius: 5px;
                border: 1px solid transparent;
                line-height: 1;
                height: 25px;
                cursor: pointer;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
                color: $iq-black-ultra;

                &.disabled {
                    background: $iq-dark-theme-color-border !important;
                    color: $iq-dark-theme-light;
                }
            }
        }

        .chart-block {
            display: flex;
            width: 100%;
            max-height: calc(100vh - 215px);
            padding-top: 11px;
            overflow-y: auto;
            overflow-x: hidden;
            //margin-top: -15px;

            .chart-container {
                flex: 1;
                margin-left: -10px;
            }

            .apexcharts-bar-series {
                transform: translate(2px, 0);
            }

            .changes {
                padding: 44px 15px 40px;
                box-sizing: border-box;
                //padding: 54px 0 90px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-basis: 190px;
            }


            .change {
                display: flex;
                align-items: center;
                font-size: 15px;
                font-weight: 400;
                color: #b2b2b2;
                white-space: nowrap;
                //margin-bottom: 7.5px;
                .sum {
                    margin-right: 20px;
                    .mt {
                        display: inline-block;
                        margin: 0 7px 0 -14px;
                    }
                }

                .rate {
                    width: 44px;
                    height: 22px;
                    font-size: 14px;
                    margin-right: 12px;
                    border-radius: 5px;
                    display: inline-flex;
                    line-height: 1;
                    align-items: center;
                    justify-content: center;
                    color: $iq-dark-theme-text;
                    background: $iq-dark-theme-bg-light-2;
                    font-weight: 500;

                    i {
                        margin-right: 3px;
                    }

                }

                .label {
                    font-size: 12px;
                    font-variant: all-small-caps;

                }

                &.newTrend {
                    .rate {
                        color: $iq-dark-theme-light;
                        font-variant: all-small-caps;
                        font-weight: 400;
                    }
                }

                &.up {
                    .rate {
                        color: $iq-color-green-bright;
                    }

                    .label {
                        color: $iq-color-green-bright;
                    }

                    &.top {
                        .rate {
                            color: #000;
                            background: $iq-bg-green-bright;
                        }
                    }
                }

                &.down {
                    .rate {
                        color: #FF2C48;
                    }

                    .label {
                        color: #FF2C48;
                    }

                    &.top {
                        .rate {
                            color: #000;
                            background: #EF7686;
                        }
                    }
                }


            }
        }


        .apexcharts-tooltip-title {
            border-bottom: 1px solid $iq-dark-theme-color-border;
            color: #fff;
        }

        .apexcharts-tooltip:after {
            content: 'Click bar to find documents';
            display: block;
            order: 99;
            font-size: 11px;
            border-top: 1px solid $iq-dark-theme-color-border;
            padding: 3px 10px;
            background: $iq-dark-theme-bg-light-2;
            color: $iq-dark-theme-light;
        }
    }

    @media only screen and (max-width: 1580px) {
        #app .fst-Dashboard .techRating {
            .filter-block,
            .rating-filter {
                align-items: flex-start;
            }

            .legend {
                max-width: 240px;
            }

            .dashboardExport {
                margin-top: 7px;
            }
        }
    }

    @media only screen and (max-width: 1420px) {
        #app .fst-Dashboard .techRating {
            .legend {
                max-width: none;
            }

            .rating-filter {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
</style>

<style scoped lang="scss">
</style>.
