<template>
    <el-dialog :title="$t('admin.users.editUser.title')" :visible="true"
               :close-on-click-modal="false"
               custom-class="dialog-custom text-left"
               width="600px" @close="close">
        <div class="add-user-form">
            <el-form label-width="165px" :model="form">
                <el-form-item :label="$t('admin.users.editUser.login')" required>
                    <el-input v-model="form.username" autocomplete="user-login" :disabled="!!id"/>
                </el-form-item>
                <el-form-item :label="$t('admin.users.editUser.password1')">
                    <el-input v-model="form.password1" type="password" show-password autocomplete="new-password"
                              @change="showSendEmail = true"/>
                </el-form-item>
                <el-form-item :label="$t('admin.users.editUser.password2')">
                    <el-input v-model="form.password2" type="password" show-password autocomplete="new-password2"/>
                </el-form-item>
                <el-form-item :label="'E-mail'" required>
                    <el-input v-model="form.email" autocomplete="user-email"/>
                </el-form-item>
                <el-form-item :label="$t('admin.users.editUser.fio')">
                    <el-input v-model="form.fullName" autocomplete="user-fio"/>
                </el-form-item>
                <el-form-item label="Company">
                    <el-select v-model="form.company" placeholder="Choose company">
                        <el-option key="comp-0" label="None" value=""></el-option>
                        <el-option v-for="item in companies"
                                   :key="`comp-${item.id}`"
                                   :label="item.name"
                                   :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('admin.users.editUser.dateAccountExpire')">
                    <el-date-picker v-model="form.dateAccountExpire" clearable/>
                </el-form-item>
                <el-form-item :label="$t('admin.users.editUser.groups')">
                    <el-select v-model="selectedGroupIds" multiple class="add-user-form__groups">
                        <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="Status">
                    <el-select v-model="form.state">
                        <el-option v-for="item in statuses" :key="item" :label="item" :value="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="Import Code">
                    <div class="flex-center">
                        <el-input v-model="importCode"></el-input>
                        <el-button plain type="primary" class="ml-2" @click="importUser()">
                            <i class="fad fa-file-import"></i>
                        </el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <div>
                <el-button type="primary" plain @click="genPassword()"><i class="fas fa-key mr-1"></i> Generate password
                </el-button>
                <span class="ml-2" v-show="showSendEmail">
                <el-checkbox v-model="form.sendEmail">
                    Send email
                </el-checkbox>
                </span>

            </div>
            <div>
                <el-button type="text" @click="close">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="save">{{ $t('save') }}</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import Utils from '@/commons/utils'
    import axios from "axios";
    import {mapState} from "vuex";

    export default {
        name: 'EditUser',
        props: {
            close: Function,
            loadUsers: Function,
            companyName: Object,
            id: String,
            reqListUser: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                form: {
                    id: this.id,
                    username: '',
                    password1: '',
                    password2: '',
                    email: '',
                    fullName: '',
                    company: '',
                    dateAccountExpire: null,
                    state: 'ENABLED',
                    sendEmail: false,
                },
                importCode: '',
                showSendEmail: false,
                selectedGroupIds: [],
                groups: [],
                companies: [],
                statuses: ['ENABLED', 'LOCKED']
            };
        },
        computed:{
            ...mapState({
                rtCompany: state => state.rtCompany,
            }),
        },
        created() {
            this.getCompanies();
            if (this.id) {
                Promise.all([this.selectUser(), this.selectGroups(), this.selectUserGroups()]).then(values => {
                    const user = values[0].data.list[0], groups = values[1].data || [],
                        groupIds = values[2].data.map(group => group.id);
                    this.form = _.mapValues(user, it => {
                        let _out = it;
                        if (typeof it === 'object') _out = it.id;
                        return _out;
                    });
                    user.dateAccountExpire = Utils.dates.toDate(user.dateAccountExpire);
                    this.groups = groups;
                    this.selectedGroupIds = groupIds;

                }, error => {
                    console.warn(error);
                });
            } else {
                this.selectGroups().then(resp => {
                    this.groups = resp.data;
                });
                if (this.reqListUser.username) {
                    this.form = this.reqListUser || {};
                    this.genPassword();
                    this.selectedGroupIds = [];
                    console.log('reqUser:', this.form)
                }

                this.showSendEmail = true;
            }
            console.log('form', this.form);
        },
        mounted() {
        },
        methods: {
            selectUser() {
                return this.$services.security.users({
                    count: 1,
                    ids: [this.id],
                    states: ['ENABLED', 'LOCKED']
                }).prepare();
            },
            selectGroups() {
                return this.$services.security.groups({
                    order: [{field: 'name', type: 'ASC'}],
                    states: ['ENABLED'],
                }).prepare();
            },
            selectUserGroups() {
                return this.$services.security.groups({
                    userIds: [this.id],
                }).prepare();
            },
            save() {
                let {password1, password2, ...user} = this.form,
                    isReq = !!this.reqListUser.username,
                    _service = isReq ? 'demo' : 'security',
                    _endpoint = isReq ? 'approve' : 'saveUser';
                let data = _.cloneDeep({
                    user, password1, password2,
                    updateDateAccountExpire: true,
                    groupBindings: {set: this.selectedGroupIds},
                    sendEmail: this.form.sendEmail
                });
                if (user.company) {
                    data.user.company = {id: user.company}
                } else {
                    data.user.company = null;
                    data.unsetCompany = true;
                }
                if (!this.id) {
                    delete data.id;
                }
                if (isReq) {
                    data = {
                        "requestId": this.reqListUser.reqId,
                        "params": data,
                    }
                }
                // this.$services.security.saveUser(data).prepare().then((resp) => {
                this.$services[_service][_endpoint](data).prepare().then((resp) => {
                    console.log('saved', resp.data);
                    this.loadUsers();
                    this.$message({
                        message: this.$t('admin.users.editUser.success'),
                        type: 'success'
                    });
                    this.close();
                });
            },
            getCompanies() {
                let _query = {
                    "fullness": "FULL",
                    "deleted": false,
                    "order": [
                        {
                            "field": "name",
                            "type": "ASC"
                        }
                    ],
                    "offset": 0,
                    "count": 8888
                };
                this.$services.companies.list(_query).then(resp => {
                    this.companies = resp.data.list;
                    this.companies.forEach(it => {
                        this.companyName[it.id] = it.name;
                    })

                });
            },
            genPassword() {
                axios.get(this.$store.state.apiUrl + '/security/generatePasswords?count=1').then(resp => {
                    if (resp.data) {
                        let pwd = resp.data[0];
                        this.form.password1 = this.form.password2 = pwd;
                        this.$copyText(pwd).then(() => {
                            this.$message({message: 'Password has been copied to clipboard', showClose: true});
                        });
                        this.form.sendEmail = this.showSendEmail = true;
                        this.form = _.cloneDeep(this.form);

                    }
                })
            },
            importUser() {
                let _str = atob(this.importCode),
                    u = JSON.parse(_str);
                if (u.email.match(/@rt.ru$/i) && this.rtCompany.id) {
                    this.form.company = this.rtCompany.id
                }

                this.form.username = u.email.split('@')[0];
                this.form.fullName = u.name;
                this.form.email = u.email;
                this.form.state = 'ENABLED';

                this.genPassword();

            },

        },
    }
</script>

<style lang="scss" scoped>
    .add-user-form {
        padding: 20px;

        &__groups {
            width: 100%;
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: space-between;
    }
</style>
