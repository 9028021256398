<template>
    <div class="widget-block SW-VentureStages" :class="{full: isFull}">
        <div class="header">
            <div class="title">
                {{ $t('dashboard.Venture_stages') }}
                <div class="viewAll"
                     v-if="this.$store.getters.swList.mode === 'all'"
                     @click="viewAll()">{{ $t('dashboard.viewAll') }}
                </div>
            </div>
            <div class="flex-center">
                <el-tooltip v-if="false && isQuarterPeriod"
                            :open-delay="400" content="Коэффициент сглаживания">
                    <el-input class="emaN" @change="refresh" v-model="emaN" type="number"
                              prefix-icon="far fa-wave-sine"
                              :max="1"
                              :min=".1" :step=".1"></el-input>
                </el-tooltip>

                <el-select v-model="periodType" @change="refresh" class="periodType">
                    <el-option value="year" :label="$t('label.byYears')"></el-option>
                    <el-option value="quarter" :label="$t('label.byQuarters')"></el-option>
                </el-select>

                <el-radio-group v-model="mode" @change="createSeries" class="changeSeries">
                    <el-radio-button label="count">{{ $t('dashboard.count') }}</el-radio-button>
                    <el-radio-button label="sum">{{ $t('dashboard.sum') }}</el-radio-button>
                </el-radio-group>
                <export-to-image :filename="$t('dashboard.Venture_stages')"
                                 selector=".chart-container"></export-to-image>
            </div>
        </div>
        <div class="chart-container"
             v-loading="loading">
            <apexchart
                    v-show="chartSeriesLoc.length"
                    ref="chart"
                    :options="chartOptions"
                    :series="chartSeriesLoc"
                    @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="noResult" v-show="chartSeriesLoc.length < 1">
                No Result
            </div>
        </div>
        <el-button @click="isFull = !isFull"
                   type="primary" plain
                   size="mini" class="toggleFull">
            <i class="fas" :class="isFull ? 'fa-compress-alt': 'fa-expand-alt'"></i>
        </el-button>

    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWVentureStages",
        components: {ExportToImage},
        props: {
            chartOptions: Object,
            ventureSubtypeFacets: Array,
            filter: Object,
            years: Object,
            params: {
                type: Object,
                default: () => {
                }
            },
        },
        data() {
            return {
                loading: false,
                mode: 'count',
                emaN: 1,
                isFull: false,
                periodType: 'year',
                subtypes: [],
                chartData: {},
                reQ: {
                    chart: {},
                },
                chartSeriesLoc: [{
                    type: 'column',
                    name: 'Seed/Pre-seed/Angel',
                    title: 'Seed/Pre-seed/Angel',
                    data: [],
                    color: 'hsl(125, 39%, 50%)',
                }, {
                    type: 'column',
                    name: 'A+ rounds',
                    title: 'A+ rounds',
                    data: [],
                    color: 'hsl(224, 44%, 57%)',
                }],
                seedStages: [
                    'seed',
                    'angel',
                    'pre_seed',
                    'convertible_note',
                    'product_crowdfunding',
                ],
                excludeStages: [
                    'undisclosed',
                    'grant',
                    'initial_coin_offering',
                    'non_equity_assistance',
                    'equity_crowdfunding',
                    'debt_financing',
                    'post_ipo_equity',
                    'post_ipo_debt',
                    'post_ipo_secondary',
                    'secondary_market',
                ]

            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            isQuarterPeriod() {
                return this.periodType === 'quarter';
            }
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let widgetFilter = _.cloneDeep(self.filter);
                if (self.params.docType) {
                    widgetFilter.types = [self.params.docType]
                }
                this.isFull = this.periodType === 'quarter';

                let query = {
                    "filter": widgetFilter,
                    "widgets": {
                        "seeds": {
                            "type": "INVT_GROWTH",
                            "filter": "VENTURE_SEED",
                            "periodType": self.periodType,
                            "years": {
                                "minBoundType": "CLOSED",
                                "maxBoundType": "CLOSED",
                                "min": self.years.min,
                                "max": self.years.max
                            }
                        },
                        "other": {
                            "type": "INVT_GROWTH",
                            "filter": "VENTURE_OTHER",
                            "periodType": self.periodType,
                            "years": {
                                "minBoundType": "CLOSED",
                                "maxBoundType": "CLOSED",
                                "min": self.years.min,
                                "max": self.years.max
                            }
                        }
                    }
                };

                self.loading = true;

                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                        // console.log('>>> canceled: ', self.reQ);
                    } catch (e) {
                        console.error(e);
                    }
                }
                self.reQ.chart = self.$services.analytics.widgets(query);
                self.reQ.chart.then(resp => {
                    self.chartData = [resp.data.widgets.seeds, resp.data.widgets.other];
                    // console.log('DATA', self.chartData);
                    self.createSeries();
                    // console.log('Series', self.chartSeriesLoc);
                }).catch(e => {
                    console.log(e);
                });
            },
            createSeries() {
                let self = this, ii = 0;
                self.chartSeriesLoc = self.chartSeriesLoc.filter(it => it.type !== 'line');
                self.chartSeriesLoc.forEach(it => {
                    it.data = [];
                })
                _.forEach(self.chartData, chart => {
                    _.orderBy(chart.years, 'year').forEach(it => {
                        self.chartSeriesLoc[ii].data.push({
                            x: (it.year + '').replace('Q', '-Q'),
                            // y: self.$utils.roundX(it.sum / Math.pow(10, (_max.toString().length - 4)), 2)
                            y: self.mode === 'sum' ? it.sum : it.count
                        });
                    });
                    if (this.isQuarterPeriod) {
                        self.chartSeriesLoc.push({
                            name: 'EMA ' + self.chartSeriesLoc[ii].name,
                            type: 'line',
                            color: self.chartSeriesLoc[ii].color,
                            data: []
                        })
                        let _data0 = _.cloneDeep(self.chartSeriesLoc[ii].data),
                            _ys = _.map(_data0, 'y'),
                            emaData = this.$utils.calculateEMA(_ys, Math.ceil(_ys.length * this.emaN))
                        self.chartSeriesLoc[ii+2].data = _data0.map((it, ind) => {
                            it.y = emaData[ind];
                            return it;
                        });
                    }
                    ii++;
                })

                // console.log('self.chartSeriesLoc ------------- ', self.chartSeriesLoc)

                let options = {
                    chart: {
                        height: '380px',
                        toolbar: {
                            export: {
                                svg: {
                                    filename: 'Venture stages'
                                },
                                png: {
                                    filename: 'Venture stages'
                                }
                            }
                        },
                    },
                    stroke: {
                        width: this.isQuarterPeriod ? [1,1,2,2] : [1, 1],
                        colors: this.isQuarterPeriod ? ['#fff'] : [],
                        curve: this.isQuarterPeriod ? 'smooth' : null,
                        dashArray: this.isQuarterPeriod ? [0, 0, 6, 6] : null,
                    },
                    legend: {
                        show: true
                    },
                    tooltip: {
                        enabled: true,
                        intersect: true,
                        shared: false,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '35%',
                        },
                    },
                    grid: {
                        show: true,
                        column: {
                            colors: this.periodType === 'quarter' ? ['transparent', 'transparent', 'transparent', 'transparent', '#7D8BC2', '#7D8BC2', '#7D8BC2', '#7D8BC2'] : ['transparent'],
                            opacity: 0.15
                        },
                        padding: {
                            left: 10,
                            right: 10
                        }
                    },
                    markers: {
                        // size: 3,
                        // hover: {
                        //     size: 7
                        // }
                    },

                    xaxis: {
                        labels: {
                            rotateAlways: this.isQuarterPeriod,
                        },
                        tooltip: {
                            enabled: false
                        },
                    },
                    yaxis: [{
                        axisBorder: {
                            offsetX: -1,
                        },
                        labels: {
                            style: {
                                fontSize: '14px'
                            },
                            formatter: function (val, index) {
                                return self.mode === 'sum' ? '$' + self.$utils.toFinHuman(val, 0).number : self.$utils.toFin(val);
                            }
                        },
                    }]
                }
                if (self.$refs && self.$refs.chart) {
                    self.$refs.chart.updateOptions(options)
                }
                setTimeout(() =>{
                    let _sel = `.SW-VentureStages .apexcharts-grid-column`,
                        rects = document.querySelectorAll(_sel);

                    rects.forEach(rect => {
                        let w = rect.getAttribute('width'),
                            x = rect.getAttribute('x') - (w-0)/2;
                        rect.setAttribute('x', x + '')
                    })
                },100)

                self.loading = false;
                // console.log('-- series VStages: ', self.chartSeries);
            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this;
                let year = (self.chartSeriesLoc[config.seriesIndex].data[config.dataPointIndex].x || '').toString(),
                    docType = "Investment";
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'
                let subtypes = config.seriesIndex ? self.ventureSubtypeFacets.filter(it => (!self.excludeStages.includes(it)
                    && !self.seedStages.includes(it)))
                    : self.seedStages;
                let facets = {
                    requests: [
                        {
                            type: yearType,
                            values: [year]
                        }, {
                            type: "INVT_TYPE",
                            values: ['venture']
                        }, {
                            type: "INVT_SUBTYPE",
                            values: subtypes
                        }
                    ]
                }
                // console.log('Facets', facets, self.filter);
                if (e.button === 0) {
                    self.viewAll(facets);
                }
            },
            viewAll(facets = null) {
                let self = this;
                let docType = "Investment";
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

                let subtypes = self.ventureSubtypeFacets.filter(it => !self.excludeStages.includes(it));

                facets = facets ? facets : {
                    requests: [{
                        type: yearType,
                        values: self.years.years
                    }, {
                        type: 'INVT_TYPE',
                        values: ['venture']
                    }, {
                        type: "INVT_SUBTYPE",
                        values: subtypes
                    }]
                }
                // console.log('Facets', facets, self.filter);
                let params = {
                    query: self.filter.nativeQuery ? self.filter.nativeQuery.value : self.filter.query,
                    isNativeQuery: !!self.filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                self.$utils.openSearch(params);

            },
        }
    }
</script>

<style scoped lang="scss">
    @media only screen and (max-width: 1690px) {
        .fst-Analytics .widget-block .header .title{
            width: 225px;
        }
    }
</style>