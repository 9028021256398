<template>
    <div class="fst-Dashboard fst-Admin dark-theme"
         :class="[`locale-${locale}`,
         {quickTourStarted: step.show && step.section === 'dashboard'}]">
        <Header
                mode="dashboard"
                :isNativeQuery="isNativeQuery"
                :predicate="filterQuery"
                from="Dashboard"
                @showHelp="handleShowHelp"
                @quickTour="startQuickTour"
                @showUserFeedback="userFeedback"
                @changeLang="createMenu()">
            <template slot="predicate">
                <PredicateEditor v-model="filterQuery"
                                 @apply="fireEvent()"
                                 :savedQueriesList="[]"
                                 @handleChangeQueryFields="handleChangeQueryFields"
                                 :placeholder="$t('placeholder.Type_query')"
                                 :isNativeQuery="isNativeQuery"
                                 ref="predicate">
                    <template v-slot:right>
                        <div class="control-group">
                            <div class="nativeSearch"
                                 v-if="user.roles.includes('ADVANCED_SEARCH')">
                                <el-switch
                                        v-model="isNativeQuery"
                                        :width="25"
                                        @change="search()"
                                        inactive-color="#46484D"
                                        active-color="#fff"
                                        :title="isNativeQuery ? 'Advanced search ON' :'Advanced search OFF'">
                                </el-switch>
                            </div>

                            <el-menu v-if="false" :default-active="activeIndex" mode="horizontal"
                                     @select="handleExperimentalMenu">
                                <el-submenu index="1"
                                            popper-class="experimentalMenu dark">
                                    <template slot="title">
                                        <div class="button">
                                            <i class="fas fa-ellipsis-h"></i>
                                        </div>
                                    </template>
                                    <el-submenu index="1-1">
                                        <template slot="title">
                                            <i class="far fa-search text-primary fa-fw mr-1 "></i>
                                            Search in:
                                        </template>
                                        <el-menu-item index="qField-all">
                                            <i class="mr-1 text-primary far"
                                               :class="[queryFields.value.length ? 'fa-circle' : 'fa-dot-circle']"></i>
                                            All fields
                                        </el-menu-item>
                                        <el-menu-item index="qField-title">
                                            <i class="mr-1 text-primary far"
                                               :class="[queryFields.value.includes('title') && queryFields.length < 2 ? 'fa-dot-circle': 'fa-circle']"></i>
                                            Title
                                        </el-menu-item>
                                        <el-menu-item index="qField-title-desc">
                                            <i class="mr-1 text-primary far"
                                               :class="[queryFields.value.includes('description') && queryFields.length > 2 ? 'fa-dot-circle': 'fa-circle']"></i>
                                            Title + description
                                        </el-menu-item>
                                        <el-menu-item index="qField-companies">
                                            <i class="mr-1 text-primary far"
                                               :class="[queryFields.value.includes('companies') ? 'fa-dot-circle': 'fa-circle']"></i>
                                            Companies
                                        </el-menu-item>
                                    </el-submenu>
                                </el-submenu>
                            </el-menu>

                        </div>
                    </template>
                </PredicateEditor>
            </template>
        </Header>
        <div class="content">
            <el-tabs v-model="activeTab" tab-position="left" :class="{hiddenLeftMenu: hiddenLeftMenu}">
                <el-tab-pane v-for="tab in tabs" :key="tab.id" :name="tab.id">
                    <div slot="label" class="side-control">
                        <div v-html="tab.title"></div>
                        <div class="control-box">
                            <component v-if="tab.control" :is="tab.control"></component>
                            <el-button type="primary" v-if="tab.button && tab.button.visible">
                                {{ tab.button.title }}
                            </el-button>
                        </div>
                    </div>
                    <div>
                        <div class="showHideLeftMenu" @click="hiddenLeftMenu = !hiddenLeftMenu">
                            <i :class="['fas',{'fa-chevron-left': !hiddenLeftMenu},{'fa-chevron-right': hiddenLeftMenu}]"></i>
                        </div>

                        <component
                                v-if="activeTab === tab.id"
                                @apply="search"
                                :is="tab.component"
                                :incEvent="firedData"
                                :params="tab.params || {}"
                                :filterQuery="filterQuery"
                                :queryFields="queryFields"
                                :customFacets="customFacets"
                                :isNativeQuery="isNativeQuery"></component>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <UserNotification></UserNotification>
        <el-dialog
                :title="modal.title"
                :close-on-click-modal="false"
                :visible.sync="modal.show"
                :modalAppendToBody="true"
                width="650px"
                :custom-class="'dialog-custom text-left ' + modal.class"
        >
            <UserFeedback v-if="modal.mode === 'Feedback'"
                          @showUserFeedback="userFeedback()">
            </UserFeedback>
        </el-dialog>
        <quick-tour></quick-tour>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Header from '../components/Header';
    import View360 from '../components/dashboard/View360';
    import TechRating from '../components/dashboard/TechnologyRating';
    import TechHeatmap from '../components/dashboard/TechnologyHeatmap';
    import TechWeakSignals from '../components/dashboard/TechnologyWeakSignals';
    import PredicateEditor from '@/components/search/PredicateEditor';
    import UserNotification from "@/components/search/UserNotification";
    import UserFeedback from '@/commons/components/UserFeedbackForm';
    import {services} from "@/commons/services";
    import i18n from '@/commons/i18n';
    import store from '@/store';
    import Help from "../components/search/Help";
    import QuickTour from "../components/settings/QuickTour";
    import {mapGetters, mapState} from "vuex";


    export default {
        name: 'Dashboard',
        components: {QuickTour, Header, UserNotification, PredicateEditor, UserFeedback},
        // components: {Header},
        data() {
            const user = this.$store.state.userInfo;
            return {
                activeTab: 'view360',
                firedData: {},
                filterQuery: '',
                locale: i18n.locale || 'en',
                activeIndex: '1',
                queryFields: {
                    name: 'titleDescription',
                    value: ["title", "description", "systemKeywords"]
                },
                isNativeQuery: false,
                hiddenLeftMenu: false,
                globalParams: window.sessionStorage.getItem('globalParams'),
                customFacets: {},
                modal: {
                    title: '',
                    show: false,
                    mode: '',
                },
                tabs: []
            };
        },
        mounted() {
            let self = this;
            services.system.info().then(function (resp) {
                if (!self.allowed.contains('ANALYTICS_DASHBOARD')) {
                    window.location = document.location.origin + store.state.contextUrl + '/#/';
                }
                if (self.isDev) {
                    console.log('~~~ :sysInfo : ~~~', resp.data);
                }
                _.each(resp.data.facets, it => {
                    self.customFacets[it.id] = it;
                });
                self.$store.commit('lastYears', resp.data.lastYear);
                self.userSettings = resp.data.userSettings || {};
                if (self.userSettings.locale) {
                    i18n.locale = self.userSettings.locale;
                    self.$store.commit('locale', self.userSettings.locale);
                }
                if (!(self.userSettings.skipTour && self.userSettings.skipTour.view360)) {
                    self.startQuickTour();
                }
                let globalParams = JSON.parse(self.globalParams || '{}');
                self.filterQuery = globalParams.query;
                self.isNativeQuery = typeof (globalParams.isNativeQuery) !== 'undefined' ? globalParams.isNativeQuery : false;
                self.queryFields = typeof globalParams.queryFields !== 'undefined' ? globalParams.queryFields : self.queryFields;
                // console.log('globalParams: ', globalParams);
                self.$refs.predicate.queryFields = self.queryFields.name || 'titleDescription';
                self.createMenu();
            });

        },
        computed: {
            ...mapState({
                user: state => state.userInfo,
                allowed: state => state.allowedActions,
            }),
            ...mapGetters({
                step: 'QTStatus'
            }),
            isDev() {
                return this.user.roles.indexOf('DEV') >= 0;
            }

        },
        methods: {
            createMenu() {
                let self = this;
                self.tabs = _.filter([
                    {
                        id: 'view360',
                        title: self.$t('dashboard.view360'),
                        component: View360,
                        visible: true,
                        // availableForRole: 'DEV',
                    },
                    {
                        id: 'heatmap',
                        title: self.$t('dashboard.heatmap'),
                        component: TechHeatmap,
                        visible: true
                    },
                    {
                        id: 'weakSignals',
                        title: self.$t('dashboard.weakSignals'),
                        component: TechWeakSignals,
                        visible: true
                    },
                    {
                        id: 'techRating',
                        title: self.$t('dashboard.techRating'),
                        component: TechRating,
                        visible: true,
                    },
                    {
                        id: 'countryRating',
                        title: self.$t('dashboard.countryRating'),
                        component: TechRating,
                        params: {isCountryRating: true},
                        visible: true,
                        // availableForRole: 'DEV',
                    },


                ], v => {
                    let hidden = v.availableForRole && !this.user.roles.includes(v.availableForRole);
                    return !hidden && (!v.hasOwnProperty('visible') || !!v.visible)
                });
                self.$refs.predicate.resizeSelect('queryFieldsSelector');
            },
            search() {
                this.saveGlobalParams();
                this.$refs.predicate.apply();
            },
            fireEvent(data) {
                this.saveGlobalParams();
                this.firedData = {incEvent: 'refresh', data: {}};
            },
            handleExperimentalMenu(item) {
                let self = this;
                switch (item) {
                    case 'qField-all':
                        self.queryFields = [];
                        self.search()
                        break;
                    case 'qField-title':
                        self.queryFields = ['title'];
                        self.search()
                        break;
                    case 'qField-title-desc':
                        self.queryFields = ['title', 'description', 'systemKeywords'];
                        self.search()
                        break;
                    case 'qField-companies':
                        self.queryFields = ['companies'];
                        self.search()
                        break;
                }
            },
            handleClearQueryFields() {
                let self = this;
                self.queryFields = {};
                self.search()
            },
            handleChangeQueryFields(item) {
                this.queryFields = item;
                this.$refs.predicate.apply();
            },
            saveGlobalParams() {
                let self = this,
                    savedFilter = {
                        query: self.filterQuery,
                        isNativeQuery: self.isNativeQuery,
                        queryFields: self.queryFields,
                        from: 'dashboard',
                    };
                // console.log('~~ savedGlobal', savedFilter);
                window.sessionStorage.setItem('globalParams', JSON.stringify(savedFilter));
            },
            handleShowHelp() {
                this.$modal.dialog(Help, {
                    title: "Help",
                    params: {
                        style: 'background: #fff;'
                    },
                    buttons: [{
                        name: 'Close',
                        type: 'text',
                        handler: data => {
                            data.dialog.close();
                        }
                    }]
                }).catch(() => {
                });
            },
            userFeedback() {
                let _modal = this.modal;
                _modal.title = this.$t('label.feedback');
                _modal.mode = 'Feedback';
                _modal.show = !_modal.show;
            },
            startQuickTour() {
                let self = this;
                self.$store.commit('QTChangeSection', {section: 'dashboard'});
                self.$store.commit('QTStart');
                let _skipTour = self.userSettings.skipTour || {};
                _skipTour.view360 = true;
                // console.log('uS', self.userSettings);
                this.$services.userSettings.save({
                    skipTour: _skipTour
                }).then(resp => {
                    console.log('userSettings: ', resp.data);
                });

            },

        },
    }
</script>

<style lang="scss">
    // --- .fst-Admin look common.scss
    #app .fst-Dashboard {
        overflow: hidden;

        .summarizationBtn {
            display: none;
        }

        &.fst-Admin {
            .el-tabs__nav.is-left{
                max-height: calc(100vh - 137px);
            }
            .el-tabs__content {
                padding-left: 30px;
            }

            .el-select {
                input.el-input__inner {
                    border: none;
                    background: none;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 500;
                    padding: 0;
                }

                [class*=" el-icon-"] {
                    font-size: 12px;
                    font-weight: 900;
                    color: #fff;
                }

                &.queryFieldsSelect {
                    input.el-input__inner {
                        color: $iq-dark-theme-light;
                        font-size: 12px;
                        height: auto;
                        padding: 0;
                        width: 165px;
                    }

                    [class*=" el-icon-"] {
                        font-size: 12px;
                        font-weight: 900;
                        color: $iq-dark-theme-light;
                    }

                }
            }

            .fst-Header {
                .changeLocale.el-select { // --- Lang ---
                    padding-left: 15px;

                    input.el-input__inner {
                        color: $iq-dark-theme-light;
                        font-weight: 600;

                    }

                    [class*=" el-icon-"] {
                        color: $iq-dark-theme-light;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }

            }
        }


        .dashboard-content {
            background: $iq-dark-theme-bg-light-1;
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;
            padding: 10px;
            //max-width: calc(100% - 75px);
            box-sizing: border-box;
            position: relative;
        }

        .wrapper {
            padding: 15px 20px 0 7px;
            margin: -20px -20px 0 0px;
            border-radius: 5px;
            min-height: calc(100vh - 104px);

            &.el-loading-parent--relative {
                overflow: hidden;
                max-height: calc(100vh - 176px);
            }
        }

        .filter-block {
            //max-width: calc(100% - 75px);
            margin: 0 0 25px;
            color: $iq-dark-theme-text;
            font-size: 15px;
            font-weight: 700;
            min-height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            select {
                background: #000;
                color: #fff;
                padding: 0;
                border: none;
                outline: none;
                font-weight: 700;
                font-size: 15px;
            }

            .filter-warning {
                position: absolute;
                font-weight: 400;
                font-size: 12px;
                color: $iq-color-danger;
                text-align: right;
                padding-top: 4px;
            }

            .fw {
                width: 210px;
            }

            .dashboardExport {
                color: $iq-dark-theme-text;

                &:hover {
                    color: $iq-dark-theme-primary;
                }
                &.disabled{
                    cursor: not-allowed;
                    &:hover {
                        color: $iq-dark-theme-text;
                    }
                }

            }
        }

        .predicate-container {
            margin-bottom: 30px;
        }

        .fst-PredicateEditor .control-group .nativeSearch {
            position: absolute;
            right: 125px;
            top: 15px;
            z-index: 350;
            font-size: 15px;
            font-weight: 400;
            cursor: pointer;
            border-radius: 4px;
            color: $iq-dark-theme-text;
            transition: all .25s ease;
            white-space: nowrap;
            background: $iq-dark-theme-bg-light-2;

            &.active {
                background: $iq-dark-theme-light;
                color: $iq-black;
                font-weight: 900;
            }
        }

        .el-button {
            border-radius: 5px;
        }

        .showHideLeftMenu {
            position: fixed;
            top: 90px;
            right: auto;
            left: 299px;
            transition: all .333s ease;

            > i {
                background: $iq-dark-theme-bg-light-1;
                border: 1px solid $iq-dark-theme-color-border;
                color: $iq-dark-theme-text;
                border-left-color: transparent;
            }

            &:before {
                border-left: 1px solid $iq-dark-theme-color-border;
            }

        }

        .hiddenLeftMenu {
            .showHideLeftMenu {
                left: 0;
            }
        }


        .apexcharts-series {
            .apexcharts-series-markers circle {
                cursor: pointer;
            }
        }

        .apexcharts-tooltip.apexcharts-theme-light {
            background: $iq-dark-theme-bg-light-1;
            border-color: $iq-dark-theme-color-border;
            color: $iq-dark-theme-text-2;
            z-index: 500;
            font-variant: all-small-caps;
            font-size: 13px !important;

            .apexcharts-tooltip-title {
                background: $iq-dark-theme-bg-light-2;
                border-bottom: 1px solid fade-out($iq-dark-theme-light, .7);
                color: $iq-dark-theme-text-2;
                padding: 5px 10px;

            }
        }
    }

    .el-popover.subFilter.dark {
        background: $iq-dark-theme-color-border;
        border: none;

        > div {
            .el-input {
                input {
                    background: transparent;
                    border: 1px solid $iq-dark-theme-text;
                    height: 25px;
                    color: #fff;
                }
            }

            .el-input__suffix {
                right: 1px;
                top: -3px;
            }

            &.input-group {
                .el-input {
                    &:last-of-type {
                        input {
                            border-right: 1px solid $iq-dark-theme-text;
                        }
                    }
                }
            }

            .el-button.el-button--small {
                height: 25px;
                padding: 0 9px;
                display: inline-flex;
                align-items: center;
                font-variant: all-small-caps;
                font-size: 14px;
                border-radius: 5px;
                margin-left: 10px;
                border: none;
                outline: none;
                transition: all .2s ease;
                background: #fff;
                color: #000;

                &:hover {
                    background: $iq-dark-theme-bg-light-2;
                    color: #fff;
                }
            }
        }
    }

    @media only screen and (max-width: 1550px) {
        #app {
            .fst-Dashboard {
                .showHideLeftMenu {
                    left: 250px;
                }

                .fst-Header .header-left .logo {
                    width: 300px;
                }
            }

        }
    }

    @media only screen and (max-width: 1200px) {
        #app {
            .fst-Header {
                .header-left {
                    flex-basis: 200px !important;
                    margin-right: 0;
                    overflow: hidden;
                }

                .appMode {
                    left: 137px;
                }
            }
        }
    }
</style>
