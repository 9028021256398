<template>
    <div class="fst-Admin wrapper">
        <Header></Header>
        <div class="content">
            <el-tabs v-model="activeTab" tab-position="left" @tab-click="handleClickTab" :class="{hiddenLeftMenu: hiddenLeftMenu}">
                <div class="showHideLeftMenu" @click="hiddenLeftMenu = !hiddenLeftMenu">
                    <i :class="['fas',{'fa-chevron-left': !hiddenLeftMenu},{'fa-chevron-right': hiddenLeftMenu}]"></i>
                </div>
                <!--div class="top_title">Admin panel</div-->
                <el-tab-pane v-for="tab in tabs" :key="tab.id" :name="tab.id" lazy>
                    <div slot="label" class="side-control">
                        <div>{{ tab.title }}</div>
                        <div class="control-box">
                            <component v-if="tab.control" :is="tab.control"
                                       @fireEvent="data => fireEvent(data)"></component>
                            <el-button type="primary" v-if="tab.button" @click="fireEvent(tab.button.event)">
                                {{ tab.button.title }}
                            </el-button>
                        </div>
                    </div>
                    <component :is="tab.component" :incEvent="firedData"></component>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="fst-project-info" title="Version">{{ $store.state.project.version }}</div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {USER_ROLES} from '@/models/Users';
    import Header from '../components/Header';
    import Users from '@/components/admin/UserList';
    import Groups from '@/components/admin/GroupList';
    import Companies from '@/components/admin/Companies';
    import Sources from '@/components/admin/Sources';
    import UserEvents from '@/components/admin/UserEvents';
    import UserFeedback from '@/components/admin/UserFeedback';
    import AdminPatterns from '@/components/admin/AdminPatterns';
    import UserNotifications from '@/components/admin/EditUserNotifications';
    import Logging from '@/components/admin/Logging';
    import CustomFacets from '@/components/admin/CustomFacets';
    import Vectors from '@/components/admin/Vectors';
    import AddSourceGroup from '@/components/admin/actions/AddSourceGroup';
    import UserNotification from "@/components/search/UserNotification";
    import UnfriendlyCountries from "@/components/admin/UnfriendlyCountries";
    import Settings from "@/components/admin/Settings";
    import SummarizationSettings from "@/components/admin/SummarizationSettings";

    export default {
        name: 'Admin',
        components: {Header, UserNotification},
        data() {
            const user = this.$store.state.userInfo;
            return {
                activeTab: 'users',
                adminPredicate: '',
                firedData: {},
                hiddenLeftMenu: false,
                tabs: []
            };
        },
        computed: {
            user() {
                return this.$store.state.userInfo;
            },
            allowed() {
                return this.$store.state.allowedActions;
            },

        },
        mounted() {
            this.createMenu();
        },
        methods: {
            createMenu() {
                this.tabs = _.filter([
                    {
                        id: 'users',
                        title: this.$t('admin.users.title'),
                        component: Users
                    }, {
                        id: 'groups',
                        title: this.$t('admin.groups.title'),
                        component: Groups
                    }, {
                        id: 'companies',
                        title: 'Companies',
                        component: Companies
                    }, {
                        id: 'unfriendlyCountries',
                        title: 'Unfriendly countries',
                        component: UnfriendlyCountries
                    }, {
                        id: 'customFacets',
                        title: 'Custom facets',
                        component: CustomFacets,
                        visible: this.allowed.contains('DEV'),
                        button: {
                            title: '+ add facet',
                            event: 'handleAddFacet',
                            visible: this.allowed.contains('DEV')
                        }
                    }, {
                        id: 'summarizationSettings',
                        title: 'Summarization settings',
                        component: SummarizationSettings,
                        visible: this.allowed.contains('DEV'),
                    }, {
                        id: 'vectors',
                        title: 'Vectors',
                        component: Vectors,
                        visible: this.allowed.contains('DEV'),
                        button: {
                            title: '+ add vector',
                            event: 'handleAddVector',
                            visible: this.allowed.contains('DEV')
                        }
                    }, {
                        id: 'sources',
                        title: this.$t('admin.sources.title'),
                        component: Sources,
                        control: AddSourceGroup
                    }, {
                        id: 'patterns',
                        title: 'Patterns',
                        component: AdminPatterns,
                        button: {
                            title: '+ pattern',
                            event: 'addPattern',
                            visible: true
                        }
                    }, {
                        id: 'userNotifications',
                        title: 'Notifications',
                        component: UserNotifications
                    }, {
                        id: 'userFeedback',
                        title: 'Feedback',
                        component: UserFeedback
                    }, {
                        id: 'userEvents',
                        title: 'Logs',
                        component: UserEvents
                    }, {
                        id: 'logging',
                        title: 'System warnings',
                        component: Logging
                    }, {
                        id: 'settings',
                        title: 'Settings',
                        component: Settings
                    }], v => !v.hasOwnProperty('visible') || !!v.visible)
            },
            fireEvent(data) {
                this.firedData = {incEvent: data};
            },
            handleAdminPredicate() {
                this.firedData = {incEvent: 'handleSearchSource', data: this.adminPredicate}
            },
            handleClickTab(e){
                let _evt = 'refresh_' + e.name;
                // console.log('event', _evt);
                this.firedData = {incEvent: _evt}

            }
        },
        created() {
            if (!this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN)) {
                this.$router.push({name: 'index'});
            }
        }
    }
</script>

<style lang="scss">
    .fst-Admin {
        height: 100%;
        display: flex;
        flex-direction: column;

        .admin-predicate {
            margin-left: -20px;
            border-radius: 5px;
            //border: 1px solid $iq-border-color-base;
            background-color: $iq-color-white;

            input {
                height: 48px;
                border-right: none;
                outline: none;
            }

            .el-input-group__append {
                background: transparent;
                //border: none;
                button {
                    color: $iq-color-blue;
                    font-size: 16px;
                    margin-top: -6px;
                }
            }
        }

        .el-tabs__nav.is-left {
            overflow-y: auto;
            max-height: calc(100vh - 180px);
        }

        .fst-project-info {
            position: absolute;
            left: 5px;
            bottom: 5px;
            color: #b6b6b6;
            font-size: 12px;
        }

        .top_title {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 20px;
            line-height: 1;
        }

        .el-button {
            font-variant: all-small-caps;
            font-size: 14px;
            font-weight: 500;
            height: 32px;
            line-height: 1;
            //padding: 0 15px;
            display: inline-flex;
            align-items: center;

            > span {
                display: flex;
                align-items: center;
            }
        }

        .content {
            flex-direction: column;
        }

        .el-tabs__header.is-left {
            min-width: 300px;
            flex: 0;
            padding-top: 20px;
        }

        .el-tabs__content {
            padding: 20px 20px 0 30px;
            background: $iq-color-grey-ultra-light;
            height: 100%;
            max-height: calc(100vh - 110px);
            overflow-y: auto;
        }

        .el-tabs__nav-wrap::after {
            background-color: $iq-border-color-base;
            width: 1px !important;
        }

        .el-tabs--left {
            height: 100%;

            .el-tabs__active-bar.is-left {
                right: auto;
                left: 0;
                width: 3px;
                min-height: 50px;
                background: $iq-color-blue;
                transition-duration: 0s;
                //margin-top: 20px;
            }

            .el-tabs__header.is-left {
                margin-right: 0;

                .side-control {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .el-button {
                        font-size: 14px;
                        height: 25px;
                    }


                    .control-box {
                        display: none;
                        margin-right: -15px;
                    }
                }

                .is-active {
                    .side-control {
                        .control-box {
                            display: flex;
                        }
                    }
                }
            }


            .el-tabs__item {
                &.is-left {
                    text-align: left;
                    color: $iq-color-text-primary;
                    font-weight: 500;
                    font-size: 15px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;

                    &.is-active {
                        color: $iq-color-blue;
                        background: $iq-color-grey-ultra-light;
                        border-top: 1px solid $iq-border-color-base;
                        border-bottom: 1px solid $iq-border-color-base;
                    }
                }
            }
        }

        .el-tabs__header.is-left {
            max-width: 300px;
            min-width: 300px;
            transition: all .333s ease;
            position: relative;
            padding-top: 44px;
        }

        .hiddenLeftMenu {
            .el-tabs__header.is-left {
                max-width: 0;
                min-width: 0;
            }

            .el-tabs__active-bar.is-left {
                display: none;
            }
        }


        .showHideLeftMenu {
            position: absolute;
            z-index: 333;
            //top: calc(50% + 10px);
            top: 0;
            right: auto;
            left: 0;

            > i {
                background: #fff;
                color: $iq-color-blue;
                cursor: pointer;
                font-size: 17px;
                padding: 3px 5px 3px 2px;
                border: 1px solid $iq-border-color-base;
                border-radius: 0 5px 5px 0;
                border-left-color: transparent;
                position: relative;
                left: -1px;
            }

            &:before {
                content: ' ';
                display: block;
                height: 20px;
                width: 10px;
                border-left: 1px solid $iq-border-color-base;
            }

        }

        .hiddenLeftMenu {
            .showHideLeftMenu {

                > i {
                    position: static;
                    padding-right: 4px;
                    padding-left: 3px;
                }

                &:before {
                    border: none
                }
            }
        }

        .el-select {
            .el-tag.el-tag--info {
                background-color: #6f93f6;
                border-color: #6f93f6;
                color: #fff;
                padding-right: 7px;

                .el-tag__close {
                    background: #fff;
                    color: #333;
                    font-weight: 500;
                    &:hover {
                        color: $iq-color-blue;
                    }
                }
            }
        }
        .el-dialog__body{
            padding: 20px;
        }
    }

</style>
