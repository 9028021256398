import { render, staticRenderFns } from "./SearchListItem.vue?vue&type=template&id=c2b3cec8&scoped=true&"
import script from "./SearchListItem.vue?vue&type=script&lang=js&"
export * from "./SearchListItem.vue?vue&type=script&lang=js&"
import style0 from "./SearchListItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b3cec8",
  null
  
)

export default component.exports