fst-SearchListItem<template>
    <div class="fst-SearchListCard" @contextmenu="handleContextMenu">
        <div class="left-block">
            <div class="logo-lang">
                <el-link :underline="false" class="moreLikeLink"
                         target="_blank"
                         :href="doc.url">
                    <div class="title" v-html="translation.title" v-if="translation.show"></div>
                    <div class="title" v-else>
                        <DocumentText :text="doc.title" :docType="doc.type" :hostingName="doc.hostingName"/>
                        <i class="fas fa-share" v-if="doc.url && doc.url.includes('http')"></i>
                    </div>
                </el-link>
            </div>
            <div class="attrs">
                <div class="attrs-item">{{ doc.date }}</div>
                <div class="attrs-item" v-if="doc.journalName" :title="doc.journalName" v-html="doc.journalName"></div>
                <div class="attrs-item" v-if="doc.url">
                    <el-link type="primary"
                             v-if="isPatent"
                             :href="doc.url" :underline="false" target="_blank">
                        {{ doc.id }}
                    </el-link>

                    <el-link type="primary"
                             v-else
                             :href="doc.url" :underline="false" target="_blank"
                             v-html="doc.website">
                    </el-link>
                </div>
            </div>
            <div class="tags" v-if="doc.keywords">
                <el-tag v-for="(item,key) in doc.keywords" :key="key" type="green">
                    <DocumentText :text="item" :docType="doc.type" :hostingName="doc.hostingName"/>
                </el-tag>
            </div>
            <UserTags
                    v-model="doc.userTags"
                    :fullUserTagList="fullUserTagList"
                    :doc="doc"
                    @updateUserTagList="getUserTagList"
                    @userTagAction="userTagAction"
                    class="mb-2">
            </UserTags>

            <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane :label="isScienceOpen ? $t('label.abstract') : $t('label.description')"
                             name="description"
                             v-if="show.descriptionTab"
                             :disabled="!translation.description && !description && !isPatent">

                    <div class="docLang">
                        <el-link :underline="false"
                                 v-for="lang in languages" :key="lang.name"
                                 v-if="isTranslationAvailable"
                                 @click="handleTranslateItem(lang.name)"
                                 :class="{active: curLang === lang.name}">

                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none" style="position: relative;top: 5px; margin: -5px -4px 0 0;">
                                <rect width="20" height="20" fill="white"></rect>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M7.99358 2.99986C8.54587 2.99986 8.99358 3.44758 8.99358 3.99986V4.70257H10.7807C10.7944 4.70228 10.8081 4.70228 10.8218 4.70257H12.3269C12.8792 4.70257 13.3269 5.15028 13.3269 5.70257C13.3269 6.25485 12.8792 6.70257 12.3269 6.70257H11.5715C11.3942 7.2829 11.1626 7.87554 10.8992 8.42696C10.5212 9.21867 10.0426 9.99981 9.50681 10.5411C9.49852 10.5495 9.49011 10.5579 9.48159 10.5664C9.98959 11.0649 10.5269 11.548 11.0479 11.9289L12.5915 7.71029C12.736 7.3153 13.1123 7.05297 13.5329 7.05392C13.9535 7.05487 14.3285 7.31889 14.4713 7.71452L16.6202 13.6705L17.4241 15.6184C17.6348 16.1289 17.3918 16.7135 16.8812 16.9242C16.3707 17.1349 15.7861 16.8919 15.5754 16.3813L15.0181 15.0311H11.9972L11.3972 16.401C11.1757 16.9069 10.5859 17.1374 10.0801 16.9159C9.57416 16.6943 9.34367 16.1046 9.56524 15.5987L10.3273 13.8588C9.44283 13.2896 8.59741 12.5108 7.92665 11.8415C7.75135 11.971 7.57421 12.0992 7.39996 12.2233C6.59587 12.7958 5.76548 13.3424 5.28655 13.621C4.80917 13.8987 4.19704 13.7368 3.91933 13.2595C3.64162 12.7821 3.80348 12.17 4.28086 11.8922C4.68465 11.6573 5.45919 11.1499 6.23999 10.594C6.33483 10.5265 6.42856 10.4591 6.52064 10.3922C5.97063 9.79109 5.43925 9.14158 5.1081 8.53017C4.84507 8.04454 5.02553 7.43764 5.51116 7.17461C5.99679 6.91158 6.60369 7.09203 6.86672 7.57766C7.1194 8.04418 7.56729 8.58074 8.08367 9.13589C8.08422 9.13535 8.08476 9.1348 8.0853 9.13426C8.39776 8.81855 8.76175 8.26185 9.09446 7.56512C9.22851 7.28441 9.35171 6.99312 9.46021 6.70257H3.49976C2.94747 6.70257 2.49976 6.25485 2.49976 5.70257C2.49976 5.15028 2.94747 4.70257 3.49976 4.70257H6.99358V3.99986C6.99358 3.44758 7.4413 2.99986 7.99358 2.99986ZM12.7743 13.0311H14.2633L13.524 10.9821L12.7743 13.0311Z"></path>
                            </svg>
                            {{ $t('label.translateToRu') }}
                        </el-link>
                    </div>

                    <div class="description" v-if="translation.show">
                        <DocumentText :text="translation.description" :docType="doc.type"
                                      :hostingName="doc.hostingName"/>
                    </div>
                    <div class="description" v-else>
                        <DocumentText :text="description" :docType="doc.type" :hostingName="doc.hostingName"/>
                    </div>
                    <div class="note" v-if="note" v-html="note"></div>
                </el-tab-pane>
                <el-tab-pane :label="$t('label.fullText')" name="fullText" class="description"
                             v-if="doc.fullText"
                             :disabled="!doc.fullText">
                    <DocumentText :text="doc.fullText" :docType="doc.type" :hostingName="doc.hostingName"/>
                </el-tab-pane>
                <el-tab-pane :label="$t('label.patentFamily')" name="patentFamily"
                             class="patentFamilyBlock" v-if="doc.patentFamily">
                    <div v-loading="patentFamily.loading">
                        <table v-if="patentFamily.result.total > 0" class="relatedResultTab">
                            <tr>
                                <th>{{$t('title')}}</th>
                                <th>{{$t('label.applicant')}}</th>
                                <th>{{$t('label.publication')}}</th>
                                <th>{{$t('label.priorityDate')}}</th>
                                <th style="width: 130px;">{{$t('label.id')}}</th>
                            </tr>
                            <tr v-for="(item, key) in patentFamily.result.list" :key="key"
                                :class="[{current: item.id === doc.id && patentFamily.result.list.length > 1}, {head: item.patentFamilyHead}]">
                                <td>
                                    <el-tooltip effect="light" placement="top" :open-delay="500"
                                                :disabled="!(item.title && item.title.length > 40 || item.id === doc.id || item.patentFamilyHead)">
                                        <div slot="content">
                                            <div v-if="item.title && item.title.length > 40" class="fw-500 mb-1">
                                                "{{item.title}}"
                                            </div>
                                            <div>
                                                <span v-if="item.patentFamilyHead" class="mr-2"><i
                                                        class="fas fa-star"></i> {{$t('label.patentFamilyHead')}} </span>
                                                <span v-if="item.id === doc.id"><i class="far fa-check-circle"></i> {{$t('label.currentPatent')}} </span>
                                            </div>
                                        </div>
                                        <el-link :href="item.innerUrl" target="_blank">
                                            <i class="fas fa-star" v-if="item.patentFamilyHead"></i>
                                            {{item.title}}
                                        </el-link>
                                    </el-tooltip>
                                </td>
                                <td>
                                    <ul class="dashed">
                                        <li v-for="(comp, ckey) in item.companies" :key="ckey">
                                            {{comp}}
                                        </li>
                                    </ul>
                                </td>
                                <td class="nowrap">
                                    {{item.date | formatDate}}
                                    <flag :iso="item.iso" :squared="false" v-if="item.iso" :title="item.countries[0]"
                                          class="ml-1"></flag>
                                    <span class="text-uppercase ml-1" v-else>{{item.iso}}</span>
                                </td>
                                <td class="nowrap">{{item.priorityDate | formatDate}}</td>
                                <td>
                                    <el-link class="link" :href="item.url" target="_blank">{{item.id}}</el-link>
                                </td>
                            </tr>
                        </table>
                        <div v-if="patentFamily.noResult" class="noResult"><h3>{{ $t('label.noResult') }}</h3></div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="isNews ? $t('label.relatedNews') : $t('label.moreLikeThis')"
                             name="relatedNews"
                             class="relatedNews">
                    <div v-loading="relatedNews.loading">
                        <SearchList v-model="relatedNews.result"
                                    v-if="relatedNews.result.total"
                                    :filter="relatedNews.filter"
                                    :noLikeThis="true"
                                    :type="doc.type"
                                    :fullUserTagList="fullUserTagList"
                                    @updateUserTagList="getUserTagList">
                        </SearchList>
                        <div v-if="relatedNews.noResult" class="noResult"><h3>{{ $t('label.noResult') }}</h3></div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('label.references')" name="references"
                             v-if="doc.citationsScience"
                             class="relatedNews">
                    <div v-loading="references.loading">
                        <SearchList v-model="references.result"
                                    v-if="references.result.total"
                                    :filter="references.filter"
                                    :noLikeThis="true"
                                    :type="doc.type"
                                    :fullUserTagList="fullUserTagList"
                                    @updateUserTagList="getUserTagList">
                        </SearchList>
                        <div v-if="references.noResult" class="noResult"><h3>{{ $t('label.noResult') }}</h3></div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('label.citedBy')" name="citedBy"
                             v-if="doc.citedByScience"
                             class="relatedNews">
                    <div v-loading="citedBy.loading">
                        <SearchList v-model="citedBy.result"
                                    v-if="citedBy.result.total"
                                    :filter="citedBy.filter"
                                    :noLikeThis="true"
                                    :type="doc.type"
                                    :fullUserTagList="fullUserTagList"
                                    @updateUserTagList="getUserTagList">
                        </SearchList>
                        <div v-if="citedBy.noResult" class="noResult"><h3>{{ $t('label.noResult') }}</h3></div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="right-block">
            <div class="attr" v-if="doc.countries && doc.countries.length">
                <div class="attr-title">{{$t(isPatent ? 'facets.patentCountry' : 'label.authorsCountries')}}</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.countries" :key="i">
                        <flag :iso="item.iso" :squared="false" v-if="item.iso" class="mr-2"></flag>
                        <span v-html="item.name"></span>
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.headCountry">
                <div class="attr-title">{{$t('facets.head_country')}}</div>
                <div class="attr-value">
                    <div>
                        <flag :iso="doc.HeadCountryIso" :squared="false" v-if="doc.HeadCountryIso" class="mr-2"></flag>
                        <span v-html="doc.headCountry"></span>
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.companies">
                <div class="attr-title" v-if="isNews">{{$t('companies')}}</div>
                <div class="attr-title" v-else-if="isPatent">{{$t('label.applicant')}}</div>
                <div class="attr-title" v-else>{{$t('label.authorsOrgs')}}</div>
                <div class="attr-value" @click="toggleShowMore('companies')">
                    <div v-for="(item, i) in doc.companies" :key="i"
                         v-show="showMore.companies || i < showMoreMax"
                         v-html="item"></div>
                    <template v-if="doc.companies.length > showMoreMax">
                        <span class="showMore" v-if="showMore.companies">{{$t('label.showLess')}}</span>
                        <span class="showMore" v-else>{{$t('label.showAll')}} ({{doc.companies.length}})</span>
                    </template>
                </div>
            </div>
            <div class="attr" v-if="doc.authors">
                <div class="attr-title">{{$t('label.authors')}}</div>
                <div class="attr-value" @click="toggleShowMore('authors')">
                    <div v-for="(item, i) in doc.authors" :key="i"
                         v-show="showMore.authors || i < showMoreMax"
                         v-html="item"></div>
                    <template v-if="doc.authors.length > showMoreMax">
                        <span class="showMore" v-if="showMore.authors">{{$t('label.showLess')}}</span>
                        <span class="showMore" v-else>{{$t('label.showAll')}} ({{doc.authors.length}})</span>
                    </template>
                </div>
            </div>
            <div class="attr" v-if="doc.publisher">
                <div class="attr-title">{{$t('label.publisher')}}</div>
                <div class="attr-value">
                    {{doc.publisher}}
                </div>
            </div>
            <div class="attr" v-if="doc.citedByScienceCount">
                <div class="attr-title">{{$t('facets.citedByScienceCount')}}</div>
                <div class="attr-value">
                    {{doc.citedByScienceCount}}
                </div>
            </div>
            <div class="attr" v-if="doc.grantCompanies">
                <div class="attr-title">{{$t('label.fundingOrganization')}}</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.grantCompanies" :key="i" v-html="item"></div>
                </div>
            </div>
            <div class="attr" v-if="doc.issn">
                <div class="attr-title">ISSN</div>
                <div class="attr-value">
                    {{doc.issn}}
                </div>
            </div>

            <div class="attr" v-if="doc.subject">
                <div class="attr-title">{{$t('facets.subject_area')}}</div>
                <template v-if="Array.isArray(doc.subject)">
                    <div class="attr-value">
                        <div v-for="(item, i) in doc.subject" :key="i" v-html="item"></div>
                    </div>
                </template>
                <template v-else>
                    <div class="attr-value" v-html="doc.subject"></div>
                </template>
            </div>
            <div class="attr asjc" v-if="doc.asjcFormat">
                <div class="attr-title">{{$t('label.journalSubjectArea')}}</div>
                <div class="attr-value">
                    <el-tooltip v-for="(item, i) in doc.asjcFormat" :key="i"
                                effect="dark" placement="top" :open-delay="500">
                        <div slot="content" v-html="item"></div>
                        <el-tag v-html="item"></el-tag>
                    </el-tooltip>
                </div>
            </div>
            <div class="attr journalRate" v-if="doc.hasOwnProperty('journalRate') && doc.journalRateName">
                <div class="attr-title">{{$t('label.citeScore')}}</div>
                <div class="attr-value" :class="doc.journalRateName">
                    <i class="fas fa-circle"></i>
                    <span class="name" v-if="doc.journalRateName">{{ doc.journalRateName }}</span>
                    <span class="val">{{ doc.journalRate }}</span>
                </div>
            </div>
            <template v-if="doc.facetValues">
                <div class="attr" v-if="doc.facetValues.trend">
                    <div class="attr-title">{{$t('label.trends')}}</div>
                    <div class="attr-value capitalize">
                        <div v-for="(item, i) in doc.facetValues.trend.items" :key="i" v-html="item.name"></div>
                    </div>
                </div>
                <div class="attr" v-if="doc.facetValues.industry">
                    <div class="attr-title">{{$t('label.industries')}}</div>
                    <div class="attr-value capitalize">
                        <div v-for="(item, i) in doc.facetValues.industry.items" :key="i" v-html="item.name"></div>
                    </div>
                </div>
            </template>
            <div class="attr" v-if="doc.urlsOther ">
                <div class="attr-title">{{$t('label.allLinks')}}</div>
                <div class="attr-value">
                    <div v-if="doc.url"><a :href="doc.url" target="_blank">{{doc.url}}</a></div>
                    <div v-for="(item, i) in doc.urlsOther " :key="i">
                        <a :href="item" target="_blank">{{item}} </a>
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.sourceType">
                <div class="attr-title">{{$t('label.type')}}</div>
                <div class="attr-value">
                    <div>
                        {{doc.sourceType}}
                        <template v-if="doc.sourceSubType">
                            ({{doc.sourceSubType}})
                        </template>
                    </div>
                </div>
            </div>

            <div class="attr" v-if="doc.patentFamily">
                <div class="attr-title">{{$t('label.patentFamily')}}</div>
                <div class="attr-value">
                    <div>
                        {{doc.patentFamily}}
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.patentFamily">
                <div class="attr-title">{{$t('label.patentFamilyHead')}}</div>
                <div class="attr-value">
                    <div>
                        {{ $t(doc.patentFamilyHead ? 'yes' : 'no') }}
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.patentFamilyHead">
                <div class="attr-title">{{$t('label.patentFamilyHead')}}</div>
                <div class="attr-value">
                    <div>
                        {{doc.patentFamilyHead}}
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.applicationDate">
                <div class="attr-title">{{$t('label.applicationDate')}}</div>
                <div class="attr-value">
                    <div>
                        {{doc.applicationDate | formatDate}}
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.priorityDate">
                <div class="attr-title">{{$t('label.priorityDate')}}</div>
                <div class="attr-value">
                    <div>
                        {{doc.priorityDate | formatDate}}
                    </div>
                </div>
            </div>
            <div class="attr" v-if="doc.date && doc.type === 'Patents'">
                <div class="attr-title">{{$t('label.publicationDate')}}</div>
                <div class="attr-value">
                    <div>
                        {{doc.date}}
                    </div>
                </div>
            </div>


            <div class="attr"
                 v-if="doc.metrics && (doc.metrics.accesses + doc.metrics.articleAccesses + doc.metrics.webOfScience + doc.metrics.crossRef) > 0">
                <div class="attr-title">{{$t('label.metrics')}}</div>
                <div class="attr-value">
                    <div class="sub-attr" v-if="doc.metrics.accesses">
                        {{$t('label.articleViews')}}: {{ doc.metrics.accesses }}
                    </div>
                    <div class="sub-attr" v-else-if="doc.metrics.articleAccesses">
                        {{$t('label.articleViews')}}: {{ doc.metrics.articleAccesses }}
                    </div>
                    <div class="sub-attr" v-if="doc.metrics.webOfScience">
                        {{$t('label.accessesFrom')}} Web of Science: {{ doc.metrics.webOfScience }}
                    </div>
                    <div class="sub-attr" v-if="doc.metrics.crossRef">
                        {{$t('label.accessesFrom')}} Crossref: {{ doc.metrics.crossRef }}
                    </div>
                </div>
            </div>
            <div class="attr cpcs" v-if="doc.cpcs && doc.cpcs.length > 0">
                <div class="attr-title">{{ $t('facets.patent_class') }}</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.cpcs" :key="i">
                        <el-popover
                                placement="bottom"
                                popper-class="cpc-block"
                                :visible-arrow="false"
                                :title="item.code"
                                width="500"
                                trigger="click">
                            <div class="path">
                                <div v-for="(des, d) in item.desc" :key="d" v-html="des"></div>
                            </div>
                            <el-button type="default" slot="reference" :class="{highlighted: item.highlighted }">
                                {{ item.code }}
                            </el-button>
                        </el-popover>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import uuid from 'uuid';
    import UserTags from '@/commons/components/UserTags.vue';
    import SearchList from './SearchList.vue';
    import DocumentText from './DocumentText';
    import i18n from '@/commons/i18n';
    import _ from "lodash";

    export default {
        name: "SearchListCard",
        components: {UserTags, DocumentText, SearchList},
        data() {
            return {
                doc: JSON.parse(window.sessionStorage.getItem('card')) || {},
                description: '',
                note: '',
                fullText: '',
                curLang: '',
                show: {
                    descriptionTab: true,
                },
                relatedNews: {
                    result: {
                        total: 0
                    },
                    filter: {},
                    loading: true,
                    noResult: false
                },
                patentFamily: {
                    result: {
                        total: 0
                    },
                    filter: {},
                    loading: true,
                    noResult: false
                },
                references: {
                    result: {
                        total: 0
                    },
                    filter: {},
                    loading: true,
                    noResult: false
                },
                citedBy: {
                    result: {
                        total: 0
                    },
                    filter: {},
                    loading: true,
                    noResult: false
                },
                activeName: 'description',
                translation: {
                    show: false,
                },
                parentPatent: {},
                languages: [
                    {name: 'ru', label: ''},
                    // {name: 'en', label: 'EN'},
                    // {name: 'de', label: 'DE'},
                    // {name: 'fr', label: 'FR'}
                ],
                fullUserTagList: [
                    {id: 1, color: 1, name: 'Favorites'},
                ],
                showMore: {
                    authors: false,
                    companies: false
                },
                showMoreMax: 5,

            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            isSciencePro() {
                return this.doc.type === 'Science';
            },
            isScienceOpen() {
                return this.doc.type === 'ScienceOpen';
            },
            isNews() {
                return this.doc.type === 'News';
            },
            isPatent() {
                return this.doc.type === 'Patents';
            },
            isScience() {
                return this.isScienceOpen || this.isSciencePro;
            },
            isTranslationAvailable() {
                return this.userInfo.roles.includes('DEV');
            },

        },
        filters: {
            formatDate: function (value) {
                return moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
            }
        },
        mounted() {
            let self = this;
            services.system.info().then(function (resp) {
                if (resp.data.userSettings) {
                    self.userSettings = resp.data.userSettings;
                    if (self.userSettings.locale) {
                        i18n.locale = self.userSettings.locale;
                        self.$store.commit('locale', self.userSettings.locale);
                    }
                }
                self.getUserTagList();
                self.refresh();
            });
            window.addEventListener('storage', () => {
                if (window.localStorage.getItem('changeUserTags')) {
                    self.getUserTagList();
                }
            });
            self.$services.userEvents.publish({
                type: 'openCard',
                data: {docIds: [{type: this.doc.type, id: this.doc.id}]}
            });
        },
        methods: {
            refresh() {
                let self = this,
                    _doc = _.cloneDeep(self.doc),
                    id = (_doc && _doc.id) ? _doc.id : self.$route.query.id || "111",
                    type = self.$route.query.type || "Science",
                    _highlightedIdx = [],
                    _highlightedCpcs = [],
                    filter = {
                        ids: [id],
                        fullness: 'FULL',
                        count: 1,
                        types: [type]
                    };
                self.$services.documents.search(filter).prepare().then(inc => {
                    let docFromDb = inc.data.list[0],
                        docWebPath = (_doc.url || '').split('/');
                    if (!Object.keys(_doc).length) { // -- If no data in sessionStorage - open by URL w/o highlighting
                        _doc = inc.data.list[0];
                        _doc.date = moment(_doc.date).format("LL")
                    }
                    if (_doc.locale) {
                        i18n.locale = _doc.locale;
                        self.$store.commit('locale', _doc.locale);
                    }
                    self.doc = Object.assign(_doc, {
                        url: _doc.url ? _doc.url : '',
                        website: docWebPath[2],
                        journalRate: self.$utils.roundX(_doc.journalRate, 2),
                        userTags: docFromDb.tags,
                        asjcFormat: (_doc.asjc || []).map(item => {
                            item.codeMain = item.codeMain.length > 2 ? item.codeMain : item.codeMain + '00';
                            return `${item.codeMain} ${item.nameMain} / ${item.code} ${item.name}`;
                        })
                    });
                    if (_doc.highlights && _doc.highlights.full) {
                        _.each(_doc.highlights.full, (it, i) => {
                            if (i.includes('cpcs')) {
                                _highlightedCpcs.push(it.replace(/<em>|<\/em>/g, ''));
                                _highlightedIdx.push(parseInt(i.replace(/cpcs\[|\]name/g, '')));
                            }
                        });
                    }
                    self.doc.cpcs = self.doc.cpcs ? (self.doc.cpcs || []).map((it, i) => {
                        let _it = it;
                        _it.desc = it.name ? it.name.toLowerCase().split('->') : [];
                        _it.highlighted = _highlightedCpcs.includes(it.codeExt) || _highlightedIdx.includes(i);
                        return _it;
                    }) : [];

                    self.doc.countries = (self.doc.countries || []).map(it => {
                        return {
                            name: it,
                            iso: self.$utils.countryToISO((it || '').toLowerCase())
                        }
                    })
                    self.doc.HeadCountryIso = self.$utils.countryToISO((self.doc.headCountry || '').toLowerCase())
                    self.doc.HeadCountryIso = self.$utils.countryToISO((self.doc.headCountry || '').toLowerCase())
                    let _issn = [];
                    if (self.doc.issnElectronic) _issn.push(self.doc.issnElectronic);
                    if (self.doc.issnPrint) _issn.push(self.doc.issnPrint);
                    if (_issn.length) {
                        self.doc.issn = _issn.join(', ');
                    }

                    if (_doc.highlights && _doc.highlights.fragments && _doc.highlights.fragments.description && _doc.highlights.fragments.description[0]) {
                        self.description = (_doc.description && _doc.description.full) ? _doc.description.full
                            : typeof (_doc.description) === 'string' ? _doc.description : '';
                    } else {
                        self.description = '';
                    }
                    if (_doc.fullText) {
                        self.fullText = _doc.fullText;
                        self.activeName = self.description ? 'description' : 'fullText';
                    }

                    if (!self.description && this.isPatent) {
                        self.searchRelated('patentFamily');
                    }

                    for (let prop in self.doc) {
                        let value = self.doc[prop];
                        if (_.isArray(value) && !value.length) {
                            delete self.doc[prop];
                        }
                    }
                    /*
                        let highlights = _.merge({full: {}, fragments: {}}, _doc.highlights),
                            keywordsToHighlights = {};


                        for (let prop in highlights.full) {
                            if (!prop || prop === 'id' || prop === 'type') {
                                continue;
                            }
                            if (prop.startsWith('systemKeywords')) {
                                keywordsToHighlights[_.get(_doc, prop)] = highlights.full[prop];
                                continue;
                            }
                            _.set(_doc, prop, highlights.full[prop]);
                        }
                    _doc.keywords = (_doc.systemKeywords || []).map(kw => keywordsToHighlights[kw] || kw);
                    */

                    _doc.keywords = _doc.systemKeywords;

                    console.log('doc:::', _doc);

                });
            },
            searchRelated(type) {
                let self = this,
                    serviceType = "search",
                    isPatent = type === 'patentFamily',
                    filter = {
                        fullness: 'FULL',
                        spam: false,
                        duplicate: false,
                        offset: 0,
                        count: 40,
                        subfilters: [
                            {
                                operator: 'AND_NOT',
                                hostingGroups: {
                                    excludeFromSearch: true
                                }
                            }
                        ],
                    };
                if (type === 'relatedNews') {
                    filter.docId = {
                        id: self.doc.id,
                        type: self.doc.type
                    }
                    serviceType = "similar"
                } else if (isPatent) {
                    filter.patentFamily = [self.doc.patentFamily];
                    filter.count = 100;
                    filter.types = ['Patents'];
                } else if (type === 'references' && self.doc.citationsScience) {
                    filter.ids = self.doc.citationsScience;
                    filter.types = [self.doc.type];
                } else if (type === 'citedBy' && self.doc.citedByScience) {
                    filter.ids = self.doc.citedByScience;
                    filter.types = [self.doc.type];
                }
                self[type].loading = true;
                console.log('related search Filter:', filter);
                self[type].filter = filter;
                self.$services.documents[serviceType](filter).then(resp => {
                    if (isPatent) {
                        resp.data.list = _.orderBy(resp.data.list, 'date', 'asc').map(it => {
                            it.innerUrl = this.$router.resolve({name: 'card'}).href + '?id=' + it.id + '&type=Patents';
                            it.iso = self.$utils.countryToISO((it.countries[0] || '').toLowerCase());
                            return it;
                        })
                        if (!self.description) {
                            let abstractDoc = resp.data.list.find(it => it.description) || {};
                            self.description = abstractDoc.description;
                            if (!self.description) {
                                self.description = '';
                                // self.show.descriptionTab = false;
                                // self.activeName = 'patentFamily';
                            } else {
                                self.note = `${self.$t('notes.abstractNotAvailableFor')}
                                ${self.doc.id}
                                <br>
                                ${self.$t('notes.abstractOfCorrespondingDocument')}
                                 <a href="${abstractDoc.innerUrl}" target="_blank">${abstractDoc.id}</a>
                                </p>`
                            }
                            this.parentPatent = abstractDoc;
                        }
                    }
                    self[type].result = resp.data;
                    self[type].loading = false;
                    if (resp.data.total === 0) {
                        self[type].noResult = true;
                    }
                    // console.log('resp',resp);
                }, error => {

                });
            },
            getUserTagList() {
                this.$services.tags.list({
                    count: 500,
                    offset: 0,
                    userIds: [this.userInfo.id]
                }).prepare().then(resp => {
                    if (resp && resp.data) {
                        this.fullUserTagList = resp.data.list;
                    }
                });
            },
            saveUserTag(item) {
                if (item) {
                    let query = {
                        id: item.id ? item.id : null,
                        name: item.name,
                        color: item.color
                    };
                    this.$services.tags.save(query).prepare().then(resp => {
                        this.getUserTagList();
                        parent.localStorage.setItem('changeUserTags', JSON.stringify(item));
                        this.$emit('updateUserTagList');
                    });
                }
            },
            deleteUserTag(tagId) {
                if (tagId) {
                    this.$services.tags.delete([tagId]).prepare().then(resp => {
                        this.fullUserTagList = this.fullUserTagList.filter(it => it.id !== tagId);
                        this.getUserTagList();
                        parent.localStorage.setItem('changeUserTags', 'deleted tag ' + tagId);
                        this.$emit('updateUserTagList');
                    });
                }
            },
            userTagAction(data) {
                parent.localStorage.setItem('userTagAction', JSON.stringify(data));
            },
            handleTabClick(e) {
                let self = this;
                if (self[e.name] && self[e.name].result && self[e.name].result.total === 0) {
                    self.searchRelated(e.name);
                }
            },
            handleContextMenu(event) {
                this.$utils.wikiTranslateContext(this, event);
            },
            handleToggleFavorite(data) {
                parent.localStorage.setItem('changeFav', JSON.stringify(data));
            },
            handleTranslateItem(lang) {
                let item = self = this,
                    toTranslate = '',
                    delimiter = ' &&& ';
                if (this.curLang === lang) {
                    this.curLang = null;
                    item.translation.show = false;
                } else if (item.translation && item.translation.title) {
                    this.curLang = lang;
                    item.translation.show = true;
                } else {
                    this.curLang = lang;
                    item.lang = lang;
                    item.translation = {
                        title: '<div class="text-info">Translation in progress...</div>',
                        description: '<div class="text-center text-info my-4 py-4"><i class="fas fa-2x fa-spinner fa-pulse text-primary"></i></div>',
                        show: true
                    };

                    // toTranslate = item.doc.title.replace(/&&&/g, '& & &') + delimiter + (item.description ? item.description.replace(/&&&/g, '& & &') : '');
                    //
                    // this.$utils.translateText({
                    //     text: toTranslate.substring(0, 4999),
                    //     tl: lang
                    // }).then(function (response) {
                    //     if (response && response.data && response.data.message) {
                    //         let _resp = (response.data.message.content || '').split(delimiter);
                    //         item.translation = _.merge({}, item.translation, {
                    //             title: _resp[0] || item.title,
                    //             description: _resp[1],
                    //             show: true
                    //         });
                    //     }
                    // });
                    let proms = [],
                        _transQ = {
                            id: item.doc.id,
                            type: item.doc.type
                        }
                    proms.push(self.$services.documents.translate(_transQ))
                    if (self.parentPatent.id) {
                        proms.push(self.$services.documents.translate({
                            id: self.parentPatent.id,
                            type: item.doc.type
                        }));
                    }
                    Promise.all(proms).then(resp => {
                        let _resp = resp[0].data,
                            _desc = self.parentPatent.id ? (resp[1] || {}).data.description :  _resp.description;
                        item.translation = _.merge({}, item.translation, {
                            title: _resp.title || item.title,
                            description: _desc || item.doc.description,
                            show: true
                        });
                    });
                }
            },
            toggleShowMore(item) {
                this.showMore[item] = !this.showMore[item];
            },
            uuid() {
                return uuid();
            }
        }
    }
</script>

<style lang="scss" scoped>
    #app .docLang {
        margin: 5px 0 10px;
    }

    .el-tabs__content > div {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
    }

    .relatedNews {

        > div {
            margin-top: 10px;
            min-height: 200px;
            max-width: 850px;
        }

        .fst-SearchListItem {
            max-height: none;
            background: #fff;
        }
    }

    .title i {
        opacity: .85;
        font-size: 0.7em;
        position: relative;
        top: -3px;
        margin-left: 7px;
    }

    .showMore {
        cursor: pointer;
        font-size: 11px;
        display: block;
        margin: 1px -3px 0;
        font-weight: 500;
        color: $iq-color-primary;
        padding: 3px;
    }

    .note {
        margin-top: 15px;
        font-weight: 500;
        line-height: 1.5;
        font-size: 14px;
        /*font-variant: all-small-caps;*/
        b {
            font-weight: 500;
        }
    }

    .current {
        opacity: .6;
        /*         color: #aaa;
               .el-link.el-link--default {
                    color: #aaa;
                    &:hover {
                        color: $iq-color-primary;
                    }
                }
         */
    }

    // --- moved to commons.scss
</style>
